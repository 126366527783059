import {
  GET_DAILY_ORDER_LIST,
  GET_DAILY_ORDER_LIST_SUCCESS,
  GET_DAILY_ORDER_LIST_FAIL,
  GET_ORDER_SUMMARY,
  GET_ORDER_SUMMARY_SUCCESS,
  GET_ORDER_SUMMARY_FAIL,
  GET_SALE_DATA,
  GET_SALE_DATA_SUCCESS,
  GET_SALE_DATA_FAIL,
  GET_SALE_DATA_DOWNLOAD,
  GET_SALE_DATA_DOWNLOAD_SUCCESS,
  GET_SALE_DATA_DOWNLOAD_FAIL,
  GET_SALE_DATA_SUMMARY,
  GET_SALE_DATA_SUMMARY_SUCCESS,
  GET_SALE_DATA_SUMMARY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dailyOrderList: [],
  totalData: {},
  dailyOrderListLoading: false,

  saleData: [],
  saleDataTotal: 0,
  saleDataLoading: false,

  saleDataDownload: [],
  saleDataDownloadTotal: 0,
  saleDataDownloadLoading: false,

  saleDataSummary: {},
  saleDataSummaryLoading: false,

  overallSummary: {},
  productQuantities: [],
  orderSummaryLoading: false,
}

const OrderAnalytics = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DAILY_ORDER_LIST:
      return {
        ...state,
        dailyOrderListLoading: true,
      }

    case GET_DAILY_ORDER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        dailyOrderListLoading: false,
      }

    case GET_DAILY_ORDER_LIST_SUCCESS:
      return {
        ...state,
        dailyOrderList: action.payload.dailyOrderList,
        totalData: action.payload,
        dailyOrderListLoading: false,
        error: {},
      }

    case GET_ORDER_SUMMARY:
      return {
        ...state,
        orderSummaryLoading: true,
      }

    case GET_ORDER_SUMMARY_FAIL:
      return {
        ...state,
        error: action.payload,
        orderSummaryLoading: false,
      }

    case GET_ORDER_SUMMARY_SUCCESS:
      return {
        ...state,
        overallSummary: action.payload.overallSummary,
        productQuantities: action.payload.productQuantities,
        orderSummaryLoading: false,
        error: {},
      }

    case GET_SALE_DATA:
      return {
        ...state,
        saleDataLoading: true,
      }

    case GET_SALE_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        saleDataLoading: false,
      }

    case GET_SALE_DATA_SUCCESS:
      return {
        ...state,
        saleData: action.payload.saleData,
        saleDataTotal: action.payload.total,
        saleDataLoading: false,
        error: {},
      }

    case GET_SALE_DATA_DOWNLOAD:
      return {
        ...state,
        saleDataDownloadLoading: true,
      }

    case GET_SALE_DATA_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        saleDataDownloadLoading: false,
      }

    case GET_SALE_DATA_DOWNLOAD_SUCCESS:
      return {
        ...state,
        saleDataDownload: action.payload.saleData,
        saleDataDownloadTotal: action.payload.total,
        saleDataDownloadLoading: false,
        error: {},
      }

    case GET_SALE_DATA_SUMMARY:
      return {
        ...state,
        saleDataSummaryLoading: true,
      }

    case GET_SALE_DATA_SUMMARY_FAIL:
      return {
        ...state,
        error: action.payload,
        saleDataSummaryLoading: false,
      }

    case GET_SALE_DATA_SUMMARY_SUCCESS:
      return {
        ...state,
        saleDataSummary: action.payload,
        saleDataSummaryLoading: false,
        error: {},
      }
    default:
      return state
  }
}

export default OrderAnalytics
