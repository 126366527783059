import MetaTag from "components/Common/Meta-tag"
import { Notification } from "components/Common/Notification"
import { debounce, map, range } from "lodash"
import MyPagination from "components/Common/MyPagination"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Container,
  Input,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap"
import { getPromoterProduct, getPromoterProfileDetails } from "store/actions"
import noImage from "assets/images/Defualt/noimage3.png"

function Products() {
  const dispatch = useDispatch()
  const [page, setPage] = useState("1")
  const [search, setSearch] = useState("")

  const { loading, products, promoterProfile } = useSelector(state => ({
    loading: state.Promoter.productLoading,
    products: state?.Promoter?.promoterProduct,
    promoterProfile: state?.Dashboard?.promoterProfile,
  }))
  const total = products?.total

  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const copyHanlde = value => {
    const removeSpace = value?.replace(/ /g, "-")
    const text = `https://toysinside.in/products/${removeSpace}?ref_id=${promoterProfile?.ref_id}`
    navigator?.clipboard?.writeText(text)
    Notification({
      type: "success",
      message: "url copied",
      title: "",
    })
  }

  useEffect(() => {
    dispatch(getPromoterProduct(search, page))
    dispatch(getPromoterProfileDetails())
  }, [dispatch, search, page])

  const debounceOrderSearch = debounce(value => setSearch(value), 600)

  return (
    <div>
      <MetaTag title="Products" />

      <div className="page-content ">
        <Container fluid>
          <div className="container-fluid ">
            <div className="w-25 m-2 ">
              <p className="text-muted font-size-18">All Products</p>
              <Input
                placeholder=" search products...... "
                type="search"
                onChange={e => debounceOrderSearch(e.target.value)}
                className="rounded-pill mt-1"
              />
            </div>
            <hr />
            <div>
              <div className="d-flex flex-wrap">
                {map(products?.products, (item, key) => (
                  <Card
                    key={key}
                    className="product-card mx-3 hvr-grow-shadow col-lg-2 col-md-3 col-sm-6 col-12"
                    style={{
                      borderRadius: "6%",
                      minHeight: "400px",
                    }}
                  >
                    <div className="position-relative">
                      <div className="avathar-md product-copy">
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            id={`copy${key}`}
                            onClick={() => copyHanlde(item?.primaryLang?.name)}
                            className="bx bx-copy icon text-muted font-size-20"
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={`copy${key}`}
                          >
                            copy url
                          </UncontrolledTooltip>
                        </span>
                      </div>
                    </div>
                    <CardBody>
                      <Link to={`/promoter/product/${item?._id}`}>
                        <div className="h-75 d-flex align-item-center justify-content-center pb-2">
                          <img
                            className="img-fluid product-img center  bg-transparent"
                            style={{
                              objectFit: "contain",
                            }}
                            src={
                              item?.images?.[0]?.url
                                ? item?.images?.[0]?.url
                                : noImage
                            }
                          />
                        </div>
                        <div>
                          <p className="font-size-14">
                            {item?.primaryLang?.name?.slice(0, 35)} &nbsp;
                            {item?.primaryLang?.name.length > 35 && "..."}
                          </p>
                          <div className="d-flex">
                            <del className="text-dark font-size-15">
                              {item?.price}
                            </del>
                            &nbsp;
                            <p className="text-success font-size-18 ">
                              ₹{item?.offerPrice}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                ))}
              </div>

              {loading ? (
                <>
                  <Spinner color="secondary" className="d-block m-auto" />
                </>
              ) : (
                <>
                  {products?.total === 0 && (
                    <p className="my-2 mx-3 ">No Products!</p>
                  )}
                </>
              )}
              <MyPagination totalPages={pages} page={page} setPage={setPage} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Products
