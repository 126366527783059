import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { CardTitle, Col, Modal, ModalBody, Row } from "reactstrap"
import { createCampaign, updateCampaign } from "store/actions"
import moment from "moment"

const CampaignModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const handleValidSubmit = (e, v) => {
    const campaign = {
      ...v,
      campaignType: v?.campaignType,
      medium: v?.medium,
    }
    const campaignUpdate = {
      ...v,
      campaignType: v?.campaignType,
      medium: v?.medium,
      status: v?.status,
    }

    if (updateData?._id) {
      dispatch(updateCampaign(updateData?._id, campaignUpdate, onCloseClick))
    } else {
      dispatch(createCampaign(campaign, onCloseClick))
    }
  }

  const formattedDate = moment(updateData?.dateFrom).format("YYYY-MM-DD")

  const mediumTypes = [
    {
      value: "",
      title: "Select medium",
    },
    {
      value: "facebook",
      title: "Facebook",
    },

    {
      value: "instagram",
      title: "Instagram",
    },
    {
      value: "whatsApp",
      title: "WhatsApp",
    },
    {
      value: "youtube",
      title: "Youtube",
    },
    {
      value: "linkedin",
      title: "Linkedin",
    },
    {
      value: "meta ad",
      title: "Meta ad",
    },
    {
      value: "other",
      title: "Other",
    },
  ]
  const statusTypes = [
    {
      value: "new",
      title: "New",
    },
    {
      value: "pending",
      title: "Pending",
    },
    {
      value: "inprogress",
      title: "Inprogress",
    },
    {
      value: "approved",
      title: "Approved",
    },
    {
      value: "cancelled",
      title: "Cancelled",
    },
  ]
  const campaignTypes = [
    {
      value: "",
      title: "Select type",
    },
    {
      value: "internal",
      title: "Internal",
    },

    {
      value: "external",
      title: "External",
    },
  ]
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <CardTitle>{updateData?._id ? "Update" : "Add New"} Campaign</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col lg={12}>
              <AvField
                name="cmp_id"
                type="text"
                placeholder="Campaign Id"
                value={updateData?.cmp_id}
                className="mb-3"
              />
            </Col>
            <Col lg={12}>
              <AvField
                name="title"
                type="text"
                placeholder="Title"
                className="mb-3"
                value={updateData?.title}
              />
            </Col>
            <Col lg={12}>
              <AvField
                name="description"
                type="textarea"
                placeholder="Enter Description"
                style={{
                  height: 80,
                }}
                className="mb-3"
                value={updateData?.description}
              />
            </Col>
            <Col md="4" lg="6" className="mb-3">
              <label htmlFor="selectField">Campaign Type</label>
              <AvField
                id="selectField"
                className="form-control"
                type="select"
                name="campaignType"
                value={updateData?.campaignType ? updateData?.campaignType : ""}
              >
                {campaignTypes.map(campaignType => (
                  <option key={campaignType.value} value={campaignType.value}>
                    {campaignType.title}
                  </option>
                ))}
              </AvField>
            </Col>
            <Col md="4" lg="6" className="mb-3">
              <label htmlFor="selectField">Medium Type</label>
              <AvField
                id="selectField"
                name="medium"
                className="form-control"
                type="select"
                value={updateData?.medium ? updateData?.medium : ""}
              >
                {mediumTypes.map(medium => (
                  <option key={medium.value} value={medium.value}>
                    {medium.title}
                  </option>
                ))}
              </AvField>
            </Col>
            {updateData && (
              <Col md="4" lg="6">
                <label htmlFor="selectField">Status</label>
                <AvField
                  id="selectField"
                  className="form-control"
                  type="select"
                  name="status"
                  value={updateData?.status ? updateData?.status : ""}
                >
                  {statusTypes.map(status => (
                    <option key={status.value} value={status.value}>
                      {status.title}
                    </option>
                  ))}
                </AvField>
              </Col>
            )}
          </Row>
          <div className="d-flex justify-content-between mt-3 align-items-center">
            <div className="d-flex">
              <div className="form-check form-switch form-switch-md">
                <label htmlFor="selectField">Date From:</label>
                <AvField name="dateFrom" type="date" value={formattedDate} />
              </div>
              <div className="form-check form-switch form-switch-md">
                <label htmlFor="selectField">Date To:</label>
                <AvField
                  name="dateTo"
                  placeholder="Campaign Type"
                  type="date"
                  value={updateData?.isPublished}
                />
              </div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

CampaignModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default CampaignModal

const deleteModalStyle = {
  width: 700,
  minWidth: 700,
}
const deleteModalBodyStyle = {
  minHeight: 500,
}
