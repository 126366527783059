import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import PropTypes from "prop-types"
import _ from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { getAllCampaigns, getAllCrms, getOrders, getUsers } from "store/actions"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import { defaultImg } from "assets/images"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"

const CrmsList = ({ userId, orderId, isMinimal = false }) => {
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const [communicationType, setCommunicationType] = useState("")
  const [selectedTransaction, setSelectedTransaction] = useState("")

  const [campaignSearch, setCampaignSearch] = useState("")
  const [campaignSelect, setCampaignSelect] = useState("Search Campaigns")
  const [campaignSelectId, setCampaignSelectId] = useState("")

  const [orderSearch, setOrderSearch] = useState("")
  const [orderSelect, setOrderSelect] = useState("Search Orders")
  const [orderSelectId, setOrderSelectId] = useState("")

  const [userSearch, setUserSearch] = useState("")
  const [userSelect, setUserSelect] = useState("Search Users")
  const [userSelectId, setUserSelectId] = useState("")

  const { loading, crms, campaigns, orders, users } = useSelector(state => ({
    loading: state.Crms.loading,
    crms: state.Crms.crms,
    campaigns: state.Campaign.campaigns,
    orders: state?.Orders?.orders,
    users: state.Users.users?.users,
    prodLoading: state.Products.loading,
    products: state.Products.products,
  }))

  const totalPages = Math.ceil(crms?.total / 10)
  const pages = range(1, totalPages + 1)

  const [sort, setSort] = useState("all")
  const [medium, setMedium] = useState("")

  useEffect(() => {
    dispatch(
      getAllCrms(
        page,
        sort,
        10,
        searchText,
        medium,
        communicationType,
        selectedTransaction,
        campaignSelectId,
        orderSelectId,
        userSelectId
      )
    )
  }, [
    dispatch,
    page,
    searchText,
    sort,
    medium,
    communicationType,
    selectedTransaction,
    campaignSelectId,
    orderSelectId,
    userSelectId,
  ])

  useEffect(() => {
    setUserSelectId(userId)
  }, [userId])

  useEffect(() => {
    setOrderSelectId(orderId)
  }, [orderId])

  const columns = [
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "order.orderId",
      text: "orderId",
      sort: true,
    },
    {
      dataField: "communicationType",
      text: " Commu. Type",
      sort: true,
    },
    {
      dataField: "transactionType",
      text: "Category",
      sort: true,
    },

    // {
    //   dataField: "imageData",
    //   filterText: "image",
    //   text: "Image",
    //   sort: true,
    // },

    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    // {
    //   dataField: "activeData",
    //   text: "Status",
    // },
    {
      dataField: "medium",
      text: "Medium",
    },
    {
      dataField: "whatsAppNumber",
      text: "WhatsApp No.",
    },

    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ]

  useEffect(() => {
    dispatch(getAllCampaigns(page, "", 10, campaignSearch))
  }, [dispatch, campaignSearch])

  //campaign select

  const debounceCampaignSearch = debounce(
    value => setCampaignSearch(value),
    600
  )
  const handleCampaignEnters = textEntered => {
    debounceCampaignSearch(textEntered)
  }
  function handlerCampaignFinalValue(event) {
    setCampaignSelect(event.label)
    setCampaignSelectId(event.value)
  }
  const campaignOptions = [
    {
      label: "All Campaigns",
      id: "",
    },
    {
      options: (campaigns?.total >= 1 ? campaigns?.campaigns : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.cmp_id || result?.title,
          value: result?._id,
        })
      ),
    },
  ]
  const tableData = map(crms?.communications, (item, index) => ({
    ...item,
    imageData: (
      <>
        <img
          src={
            item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
          }
          width="50px"
        />
      </>
    ),
    createdAt: moment(item.createdAt).format("ll"),
    transactionType: _.startCase(_.camelCase(item?.transactionType)),
    // title: (
    //   <div className="d-flex algin-items-center text-start   justify-content-start ">
    //     <p className="mb-0 mt-1">{item?.title}</p>
    //     {sort === "all" && (
    //       <>
    //         {item?.isPublished && (
    //           <Badge
    //             className={"font-size-11 mx-2 p-2 badge-soft-success "}
    //             style={{
    //               width: "fit-content",
    //             }}
    //             pill
    //           >
    //             Published
    //           </Badge>
    //         )}
    //         {item?.isHighlighted && (
    //           <Badge
    //             className={"font-size-11 me-2 p-2 badge-soft-info  "}
    //             style={{
    //               width: "fit-content",
    //             }}
    //             pill
    //           >
    //             Highlighted
    //           </Badge>
    //         )}
    //       </>
    //     )}
    //   </div>
    // ),
    // content: (
    //   <div
    //     className="text-break-spaces position-relative"
    //     style={{ maxWidth: 350 }}
    //   >
    //     <Markup content={item?.content?.slice(0, 140)} className="markup" />
    //     <span
    //       style={{
    //         position: "absolute",
    //         bottom: 0,
    //         right: 100,
    //       }}
    //     >
    //       {" "}
    //       {item?.content?.length > 140 ? "..." : ""}
    //     </span>
    //   </div>
    // ),
    // activeData: (
    //   <>
    //     <div className="form-check form-switch form-switch-md mb-3">
    //       <input
    //         type="checkbox"
    //         className="form-check-input"
    //         id="customSwitchsizemd"
    //         onChange={e => {
    //           handleSwitchChange(item, e)
    //         }}
    //         name="isActive"
    //         checked={item?.isActive}
    //         style={{ cursor: "pointer" }}
    //       />
    //       <label className="form-check-label" htmlFor="customSwitchsizemd">
    //         {item?.isActive ? `Active` : `Inactive`}
    //       </label>
    //     </div>
    //   </>
    // ),
    // isPublished: (
    //   <>
    //     <div className="form-check form-switch form-switch-md mb-3">
    //       <input
    //         type="checkbox"
    //         className="form-check-input"
    //         id="customSwitchsizemd"
    //         onChange={e => {
    //           handleSwitchChange(item, e)
    //         }}
    //         name="isPublished"
    //         checked={item?.isPublished}
    //         style={{ cursor: "pointer" }}
    //       />
    //       <label className="form-check-label" htmlFor="customSwitchsizemd">
    //         {item?.isPublished ? `Yes` : `No`}
    //       </label>
    //     </div>
    //   </>
    // ),
    // isHighlighted: (
    //   <>
    //     <div className="form-check form-switch form-switch-md mb-3">
    //       <input
    //         type="checkbox"
    //         className="form-check-input"
    //         id="customSwitchsizemd"
    //         onChange={e => {
    //           handleSwitchChange(item, e)
    //         }}
    //         name="isHighlighted"
    //         style={{ cursor: "pointer" }}
    //         checked={item?.isHighlighted}
    //       />
    //       <label className="form-check-label" htmlFor="customSwitchsizemd">
    //         {item?.isHighlighted ? `Highlighted` : `Normal`}
    //       </label>
    //     </div>
    //   </>
    // ),
    action: (
      <div>
        <Link
          to={`#`}
          className="btn-md"
          onClick={() => setBlogModal({ status: true, data: item })}
        >
          <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
          />
        </Button>
      </div>
    ),
  }))
  useEffect(() => {
    dispatch(getOrders(page, 10, orderSearch))
  }, [dispatch, page, orderSearch])

  const debounceOrderSearch = debounce(value => setOrderSearch(value), 600)
  const handleOrderEnters = textEntered => {
    debounceOrderSearch(textEntered)
  }
  function handlerOrderFinalValue(event) {
    setOrderSelect(event.label)
    setOrderSelectId(event.value)
  }
  const OrderOptions = [
    {
      label: "All Orders",
      id: "",
    },
    {
      options: (orders?.length >= 1 ? orders : [])?.map((result, index) => ({
        key: index,
        label: result?.orderId,
        value: result?._id,
      })),
    },
  ]
  useEffect(() => {
    dispatch(getUsers(page, "", 10, userSearch))
  }, [dispatch, page, userSearch])

  const debounceUserSearch = debounce(value => setUserSearch(value), 600)

  const handleUserEnters = textEntered => {
    debounceUserSearch(textEntered)
  }
  function handlerUserFinalValue(event) {
    setUserSelect(event.label)
    setUserSelectId(event.value)
  }
  const UserOptions = [
    {
      label: "All Users",
      id: "",
    },
    {
      options: (users?.length >= 1 ? users : [])?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  const status = [
    {
      value: "all",
      title: "All",
    },
    {
      value: "pending",
      title: "Pending",
    },
    {
      value: "success",
      title: "Success",
    },
    {
      value: "errored",
      title: "Errored",
    },
  ]
  const mediumTypes = [
    {
      value: "",
      title: "All",
    },
    {
      value: "email",
      title: "Email",
    },
    {
      value: "whatsapp",
      title: "Whatsapp",
    },
  ]
  const communicationTypes = [
    {
      value: "",
      title: " Select Communication",
    },
    {
      value: "transaction",
      title: "Transaction",
    },
    {
      value: "promotion",
      title: "Promotion",
    },
    {
      value: "others",
      title: "Others",
    },
  ]
  const transactionTypes = [
    {
      value: "",
      title: "Select Transaction",
    },
    {
      value: "cod confirmation",
      title: "COD Confirmation",
    },

    {
      value: "payment failed",
      title: "Payment Failed",
    },
    {
      value: "shipped",
      title: "Shipped",
    },
    {
      value: "delivered",
      title: "Delivered",
    },
    {
      value: "others",
      title: "Others",
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {!isMinimal && (
                      <>
                        <Row className="justify-content-between mb-2">
                          <Col lg={3} className="">
                            <div
                              className="btn-group mt-xl-0"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              {status?.map((item, key) => (
                                <Fragment key={key}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnRadio"
                                    id={`btnRadio${item?.value}`}
                                    autoComplete="off"
                                    checked={item.value === sort ? true : false}
                                    onChange={() => setSort(item?.value)}
                                  />
                                  <label
                                    className="btn btn-success"
                                    htmlFor={`btnRadio${item?.value}`}
                                  >
                                    {item?.title}
                                  </label>
                                </Fragment>
                              ))}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <AvForm>
                                  <AvField
                                    name="searchText"
                                    placeholder="Search"
                                    type="text"
                                    onChange={e =>
                                      debounceBlogSearch(e.target.value)
                                    }
                                  />
                                </AvForm>
                                {/* <SearchBar {...toolkitProps.searchProps} /> */}
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col lg={3} className="">
                            <div
                              className="btn-group mt-xl-0"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              {mediumTypes?.map((item, key) => (
                                <Fragment key={key}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnRadio1"
                                    id={`btnradio${item.value}`}
                                    autoComplete="off"
                                    checked={
                                      item.value === medium ? true : false
                                    }
                                    onChange={() => setMedium(item.value)}
                                  />
                                  <label
                                    className="btn mb-0 btn-outline-info d-flex align-items-center w-100"
                                    htmlFor={`btnradio${item.value}`}
                                  >
                                    {item?.title}
                                  </label>
                                </Fragment>
                              ))}
                            </div>
                          </Col>
                          {/* <Col md="4" lg={2} className="mb-2">

                        <select
                          id="selectField"
                          className="form-control"
                          value={selectedMedium}
                          onChange={e => setSelectedMedium(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Medium
                          </option>
                          {mediumTypes.map((medium, key) => (
                            <option key={key} value={medium.value}>
                              {medium.title}
                            </option>
                          ))}
                        </select>
                      </Col> */}

                          {/* <Col lg={3}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => setBlogModal({ status: true })}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create Campaign
                          </Button>
                        </div>
                      </Col> */}
                        </Row>
                        <Row className="mb-3">
                          {/* order */}
                          {!orderId && (
                            <Col className="mb-2" lg={userId ? 3 : 2}>
                              <FormGroup className="w-350 ">
                                <div className="ajax-select mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleOrderEnters}
                                    value={orderSelect}
                                    placeholder={orderSelect}
                                    onChange={handlerOrderFinalValue}
                                    options={OrderOptions}
                                    classNamePrefix="select2-selection"
                                    // isLoading={loading}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          )}
                          {/* user */}

                          {!userId && (
                            <Col className="mb-2 drop" lg={orderId ? 3 : 2}>
                              <FormGroup className="w-350 ">
                                <div className="ajax-select mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleUserEnters}
                                    value={userSelect}
                                    placeholder={userSelect}
                                    onChange={handlerUserFinalValue}
                                    options={UserOptions}
                                    classNamePrefix="select2-selection"
                                    // isLoading={loading}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          )}

                          <Col className="mb-2" lg={3}>
                            <FormGroup className="w-350">
                              <div className="ajax-select mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleCampaignEnters}
                                  value={campaignSelect}
                                  placeholder={campaignSelect}
                                  onChange={handlerCampaignFinalValue}
                                  options={campaignOptions}
                                  classNamePrefix="select2-selection"
                                  // isLoading={loading}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4" lg={2} className="mb-2">
                            {/* <label htmlFor="selectField">Status</label> */}
                            <select
                              id="selectField"
                              className="form-control"
                              value={selectedTransaction}
                              onChange={e =>
                                setSelectedTransaction(e.target.value)
                              }
                            >
                              {transactionTypes.map((transaction, key) => (
                                <option key={key} value={transaction.value}>
                                  {transaction.title}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col md="4" lg={2} className="mb-2 padding-3">
                            {/* <label htmlFor="selectField">Status</label> */}
                            <select
                              id="selectField"
                              className="form-control"
                              value={communicationType}
                              onChange={e =>
                                setCommunicationType(e.target.value)
                              }
                            >
                              {communicationTypes?.map((communication, key) => (
                                <option key={key} value={communication.value}>
                                  {communication.title}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                      </>
                    )}
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {crms?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Campaigns !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CrmsList
CrmsList.propTypes = {
  userId: PropTypes.object,
  orderId: PropTypes.object,
  isMinimal: PropTypes.bool,
}
