import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  FormGroup,
  Label,
} from "reactstrap"
import Select from "react-select"

import { Row, Col, Container } from "reactstrap"
import { TabContent, TabPane } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import {
  getAllProducts,
  getCategories,
  getCollections,
  getGroupsDetails,
  updateGroup,
} from "store/actions"
import { debounce, map } from "lodash"
import ImageInput from "components/Common/MyInput"
import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"
import { GroupTypesData, LayoutData } from "./Create"
import { useSiteVersion } from "hooks/useSiteVersion"
import UseImageUploader from "components/imageUploader/useImageUploader"

const UpdateGroups = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const { isPremium, isBasic } = useSiteVersion()
  const {
    groupDetails,
    loading,
    Collections,
    CollectionLoading,
    categories,
    catLoading,
    products,
    proLoading,
  } = useSelector(state => ({
    loading: state.Group.loading,
    groupDetails: state.Group.groupDetails,
    Collections: state.Collection.collections,
    CollectionLoading: state.Collection.loading,
    categories: state.Category.categories,
    catLoading: state.Category.loading,
    products: state.Products.products,
    proLoading: state.Products.loading,
  }))

  // const [bannerImages, setBannerImages] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  // const [finalStoreId, setFinalStoreId] = useState()

  //collection///
  const [collectionSelect, setCollectionSelect] = useState({
    searchText: "",
    id: "",
    title: "Search a Collection",
  })
  const [selectedCollectionArray, setSelectedCollectionArray] = useState([])
  const [selectedCollectionId, setSelectedCollectionId] = useState([])
  /////

  //category///
  const [categorySelect, setCategorySelect] = useState({
    searchText: "",
    id: "",
    title: "Search a Category",
  })
  const [selectedCategoryArray, setSelectedCategoryArray] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState([])
  /////

  //product///
  const [productSelect, setproductSelect] = useState({
    searchText: "",
    id: "",
    title: "Search a Product",
  })
  const [selectedProductArray, setSelectedProductArray] = useState([])
  const [selectedProductId, setSelectedProductId] = useState([])
  /////

  const [layout, setLayout] = useState("")
  const [groupType, setGroupType] = useState("")
  const [platform, setPlatform] = useState({
    web: false,
    mobile: false,
    common: true,
    title: "common",
  })

  useEffect(() => {
    dispatch(getCollections(1, "", collectionSelect?.searchText))
  }, [dispatch, collectionSelect?.searchText])

  useEffect(() => {
    dispatch(getCategories(1, "", categorySelect?.searchText))
  }, [dispatch, categorySelect?.searchText])

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSelect?.searchText))
  }, [dispatch, productSelect?.searchText])

  function handleValidSubmit(values) {
    const updateGroupData = {
      // store: finalStoreId?._id,
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      images: bannerImagesPreview,
      priority: parseInt(values.priority),
      platform: platform.title,
      layout: layout,
      type: groupType,

      categories: selectedCategoryId,
      collections: selectedCollectionId,
      singleCollection: selectedCategoryId[0],
      singleCategory: selectedCollectionId[0],
      products: selectedProductId,
    }
    dispatch(updateGroup(updateGroupData, groupDetails?._id, history))
  }

  // const onChangeImageHandler = e => {
  //   const files = Array.from(e.target.files)

  //   setBannerImagesPreview([])
  //   setBannerImages([])

  //   files.forEach(file => {
  //     const reader = new FileReader()

  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setBannerImagesPreview(oldArray => [
  //           ...oldArray,
  //           { url: reader.result, id: oldArray.length + 1 },
  //         ])
  //         setBannerImages(oldArray => [...oldArray, reader.result])
  //       }
  //     }

  //     reader.readAsDataURL(file)
  //   })
  // }

  useEffect(() => {
    dispatch(getGroupsDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    setLayout(groupDetails?.layout)
  }, [groupDetails])

  // const BannerImage = groupDetails?.images

  // const onDeleteImg = id => {
  //   setBannerImagesPreview(bannerImagesPreview?.filter(item => item.id !== id))
  // }

  //collection dropdown
  const debounceCollectionSearch = debounce(
    value => setCollectionSelect(value),
    600
  )
  const handleCollectionEnters = textEntered => {
    debounceCollectionSearch({
      ...collectionSelect,
      searchText: textEntered,
    })
  }

  function handlerCollectionFinalValue(event) {
    const collection = {
      ...collectionSelect,
      title: event.label,
      id: event.value,
      image: event.image,
    }
    setCollectionSelect(collection)

    if (groupType === "single collection") {
      setSelectedCollectionArray([collection])
      setSelectedCollectionId([collection?.id])
    } else {
      setSelectedCollectionArray([...selectedCollectionArray, collection])
      setSelectedCollectionId([...selectedCollectionId, collection?.id])
    }
  }

  const collectionOptions = [
    {
      options:
        Collections?.length &&
        Collections?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.title,
          value: result._id,
          image: result?.logoImages[0]?.url,
        })),
    },
  ]

  const handleRemoveSeletedCollection = id => {
    const filteredColl = selectedCollectionArray?.filter(i => i.id !== id)
    setSelectedCollectionArray(filteredColl)

    const filteredCollId = selectedCollectionId?.filter(i => i === id)
    setSelectedCollectionId(filteredCollId)
  }
  //end of collection dropdown

  //category dropdown
  const debounceCategorySearch = debounce(
    value => setCategorySelect(value),
    600
  )
  const handleCategoryEnters = textEntered => {
    debounceCategorySearch({
      ...categorySelect,
      searchText: textEntered,
    })
  }

  function handlerCategoryFinalValue(event) {
    const category = {
      ...categorySelect,
      title: event.label,
      id: event.value,
      image: event.image,
    }
    setCategorySelect(category)
    if (groupType === "single category") {
      setSelectedCategoryArray([category])
      setSelectedCategoryId([category?.id])
    } else {
      setSelectedCategoryArray([...selectedCategoryArray, category])
      setSelectedCategoryId([...selectedCategoryId, category?.id])
    }
  }

  const categoryOptions = [
    {
      options:
        categories?.length &&
        categories?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result._id,
          image: result?.logoImages[0]?.url,
        })),
    },
  ]

  const handleRemoveSeletedCategory = id => {
    const filteredCate = selectedCategoryArray?.filter(i => i.id !== id)
    setSelectedCategoryArray(filteredCate)

    const filteredCateId = selectedCategoryId?.filter(i => i !== id)
    setSelectedCategoryId(filteredCateId)
  }
  //end of category dropdown

  //product dropdown
  const debounceProductSearch = debounce(value => setproductSelect(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch({
      ...productSelect,
      searchText: textEntered,
    })
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.label,
      id: event.id,
      image: event.image,
    }
    setproductSelect(product)
    setSelectedProductArray([...selectedProductArray, product])
    setSelectedProductId([...selectedProductId, product?.id])
  }

  const productOptions = [
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result?.varients?.length >= 1
              ? result?.varients[0]?.value &&
                `(${result?.varients[0]?.value} ${result?.varients[0]?.unit})`
              : ""
          }`,
          id: result?._id,
          image: result?.images[0]?.url,
          value: result?.varients[0]?.value,
          unit: result?.varients[0]?.unit,
        })
      ),
    },
  ]

  const handleRemoveSeletedproduct = id => {
    const filteredProd = selectedProductArray?.filter(i => i.id !== id)
    setSelectedProductArray(filteredProd)

    const filteredProdId = selectedProductId?.filter(i => i !== id)
    setSelectedProductId(filteredProdId)
  }

  //end of product dropdown

  const isMult = LanguageSwitch()?.isMult

  //update initial values
  useEffect(() => {
    setPlatform({
      title: groupDetails?.platform,
      web: groupDetails?.platform === "web" ? true : false,
      mobile: groupDetails?.platform === "mobile" ? true : false,
      common: groupDetails?.platform === "common" ? true : false,
    })
    setGroupType(groupDetails?.type)

    //category
    const updateCatToSelectFormat = map(groupDetails?.categories, cat => ({
      title: cat?.primaryLang?.name,
      image: cat?.logoImages[0]?.url,
      id: cat?._id,
    }))
    setSelectedCategoryArray(updateCatToSelectFormat)
    const updateCatToIdsArray = map(groupDetails?.categories, prod => prod?._id)
    setSelectedCategoryId(updateCatToIdsArray)

    //collection
    const updateCollToSelectFormat = map(groupDetails?.collections, coll => ({
      title: coll?.primaryLang?.title,
      image: coll?.logoImages[0]?.url,
      id: coll?._id,
    }))
    setSelectedCollectionArray(updateCollToSelectFormat)
    const updateCollToIdsArray = map(
      groupDetails?.collections,
      prod => prod?._id
    )
    setSelectedCollectionId(updateCollToIdsArray)

    //prodcut
    const updatePordToSelectFormat = map(groupDetails?.products, prod => ({
      title: prod?.primaryLang?.name,
      image: prod?.images[0]?.url,
      id: prod?._id,
    }))
    setSelectedProductArray(updatePordToSelectFormat)
    const updateProdToIdsArray = map(groupDetails?.products, prod => prod?._id)
    setSelectedProductId(updateProdToIdsArray)

    setBannerImagesPreview(groupDetails?.images)

    // setFinalStoreId(groupDetails?.store)
  }, [groupDetails])

  const handleGroupTypes = () => {
    if (isPremium) return GroupTypesData
    else if (isBasic)
      return GroupTypesData?.filter(
        gp => gp.value !== "single collection" && gp.value !== "collections"
      )
  }

  const handleGroupLayouts = () => {
    if (isPremium) return LayoutData
    else if (isBasic)
      return LayoutData?.filter(
        layout =>
          layout.value === "list_vertical" || layout.value === "list_horizontal"
      )
  }

  return (
    <>
      <MetaTag title={"Update Groups"} />

      <div className="page-content">
        <Breadcrumbs title="Classifications" breadcrumbItem="Update Groups" />
        <Container fluid>
          <div className="container-fluid">
            {isPremium && (
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      {/* <Col className="col-4">
                      <CardTitle className="h4 mb-4 text_deals_green">
                        Store
                      </CardTitle>
                      <AvForm>
                        <div className="mb-3">
                          <AvField
                            name="store"
                            value={groupDetails?.store?.storeName}
                            label=""
                            type="text"
                            readOnly
                          />
                        </div>
                      </AvForm>
                    </Col> */}
                      <Col className="col-6">
                        <div className="w-100">
                          <CardTitle className="h4 mb-4 text_deals_green">
                            Platform
                          </CardTitle>
                          <Row className="my-2">
                            <div className="col-4  align-items-center  d-flex">
                              <input
                                type="radio"
                                name="Platform"
                                id={"common"}
                                value={"common"}
                                onChange={v => {
                                  setPlatform({
                                    ...setPlatform,
                                    title: "common",
                                    common: v,
                                  })
                                }}
                                checked={platform?.common}
                              />
                              <label
                                className="mb-0 pb-0 mx-3 cursor-pointer"
                                htmlFor={"common"}
                              >
                                Common
                              </label>
                            </div>
                            <div className="col-4  align-items-center  d-flex">
                              <input
                                type="radio"
                                name="Platform"
                                id={"web"}
                                checked={platform?.web}
                                value={"web"}
                                onChange={v => {
                                  setPlatform({
                                    ...setPlatform,
                                    title: "web",
                                    web: v,
                                  })
                                }}
                              />
                              <label
                                className="mb-0 pb-0 mx-3 cursor-pointer"
                                htmlFor={"web"}
                              >
                                Web
                              </label>
                            </div>
                            <div className="col-4  align-items-center  d-flex">
                              <input
                                type="radio"
                                name="Platform"
                                id={"mobile"}
                                value={"mobile"}
                                onChange={v => {
                                  setPlatform({
                                    ...setPlatform,
                                    title: "mobile",
                                    mobile: v,
                                  })
                                }}
                                checked={platform?.mobile}
                              />
                              <label
                                className="mb-0 pb-0 mx-3 cursor-pointer"
                                htmlFor={"mobile"}
                              >
                                Mobile
                              </label>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4 text_deals_green">
                    Group Type
                  </CardTitle>
                  <Row>
                    <div
                      className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      {map(handleGroupTypes(), (item, key) => (
                        <div key={key} className="me-3">
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id={item.value}
                            autoComplete="off"
                            checked={groupType === item.value}
                            value={item.value}
                            onChange={e => setGroupType(e.target.value)}
                          />
                          <label
                            className="btn btn-outline-info"
                            htmlFor={item.value}
                          >
                            {item.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green">
                        Layout
                      </CardTitle>
                      <Row>
                        <div
                          className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {map(handleGroupLayouts(), (item, key) => (
                            <div key={key} className="me-3">
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={item.id}
                                autoComplete="off"
                                value={item.value}
                                onChange={e => setLayout(e.target.value)}
                                checked={layout === item.value}
                              />
                              <label
                                className="btn btn-outline-info"
                                htmlFor={item.id}
                              >
                                {item.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {groupType?.length >= 1 && (
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Row>
                          {isPremium && (
                            <>
                              {(groupType === "collections" ||
                                groupType === "single collection") && (
                                <Col xl="4" lg="4" md="6" sm="12">
                                  <CardTitle className="h4 mb-4 text_deals_green ">
                                    Collections
                                  </CardTitle>
                                  <FormGroup className="mb-3">
                                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                      <Select
                                        onInputChange={handleCollectionEnters}
                                        value={collectionSelect?.title}
                                        placeholder={collectionSelect?.title}
                                        onChange={handlerCollectionFinalValue}
                                        options={collectionOptions}
                                        classNamePrefix="select2-selection"
                                        isLoading={CollectionLoading}
                                      />
                                    </div>
                                  </FormGroup>
                                  {map(selectedCollectionArray, (item, key) => (
                                    <div key={key} className="mb-2">
                                      <div className="d-flex align-items-center">
                                        <img
                                          className="img-thumbnail rounded-circle "
                                          style={{
                                            height: "60px",
                                            width: "60px",
                                          }}
                                          src={item?.image}
                                          alt="product"
                                        />
                                        <p className="pb-0 mb-0 px-3">
                                          {item?.title}
                                        </p>
                                        <i
                                          className="bx bx-trash-alt "
                                          style={{
                                            fontSize: "1rem",
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleRemoveSeletedCollection(
                                              item?.id
                                            )
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  ))}
                                </Col>
                              )}
                            </>
                          )}

                          {(groupType === "categories" ||
                            groupType === "single category") && (
                            <Col xl="4" lg="4" md="6" sm="12">
                              <CardTitle className="h4 mb-4 text_deals_green ">
                                Category
                              </CardTitle>
                              <FormGroup className="mb-3">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleCategoryEnters}
                                    value={categorySelect?.title}
                                    placeholder={categorySelect?.title}
                                    onChange={handlerCategoryFinalValue}
                                    options={categoryOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={catLoading}
                                  />
                                </div>
                              </FormGroup>
                              {map(selectedCategoryArray, (item, key) => (
                                <div key={key} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <img
                                      className="img-thumbnail rounded-circle "
                                      style={{ height: "60px", width: "60px" }}
                                      src={item?.image}
                                      alt="product"
                                    />
                                    <p className="pb-0 mb-0 px-3">
                                      {item?.title}
                                    </p>
                                    <i
                                      className="bx bx-trash-alt "
                                      style={{
                                        fontSize: "1rem",
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleRemoveSeletedCategory(item?.id)
                                      }
                                    ></i>
                                  </div>
                                </div>
                              ))}
                            </Col>
                          )}
                          {(groupType === "single category" ||
                            groupType === "single collection") && (
                            <Col xl="4" lg="4" md="6" sm="12">
                              <CardTitle className="h4 mb-4 text_deals_green ">
                                Product
                              </CardTitle>
                              <FormGroup className="mb-3">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleProductEnters}
                                    value={productSelect?.title}
                                    placeholder={productSelect?.title}
                                    onChange={handlerProductFinalValue}
                                    options={productOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={proLoading}
                                  />
                                </div>
                              </FormGroup>
                              {map(selectedProductArray, (item, key) => (
                                <div key={key} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <img
                                      className="img-thumbnail rounded-circle "
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                      src={item?.image}
                                      alt="product"
                                    />
                                    <p className="pb-0 mb-0 px-3">
                                      {item?.title}{" "}
                                      {item?.value &&
                                        `(${item?.value} ${item?.unit})`}
                                    </p>
                                    <i
                                      className="bx bx-trash-alt "
                                      style={{
                                        fontSize: "1rem",
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleRemoveSeletedproduct(item?.id)
                                      }
                                    ></i>
                                  </div>
                                </div>
                              ))}
                            </Col>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                <Col xl="12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}

                      <Col xl="4" lg="4" md="6" sm="12">
                        <CardTitle className="h4 mb-4 text_deals_green">
                          Priority
                        </CardTitle>
                        <div className="mb-3">
                          <AvField
                            name="priority"
                            value={groupDetails?.priority}
                            label=""
                            type="number"
                          />
                        </div>
                      </Col>

                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.title"
                                    label="Title"
                                    type="text"
                                    value={
                                      groupDetails?.primaryLang?.title || ""
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.description"
                                    label="Description"
                                    type="textarea"
                                    errorMessage="Invalid description"
                                    rows="7"
                                    value={
                                      groupDetails?.primaryLang?.description ||
                                      ""
                                    }
                                  />
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col xl="12">
                              <Row>
                                <Col className="col-12 d-flex flex-wrap">
                                  <Col className="col-12 d-flex flex-wrap">
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        <CardSubtitle>Name</CardSubtitle>
                                        <CardSubtitle>عنوان</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: "right" }}
                                        name="secondaryLang.title"
                                        label=""
                                        type="text"
                                        value={
                                          groupDetails?.secondaryLang?.title ||
                                          ""
                                        }
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        <CardSubtitle>Description</CardSubtitle>
                                        <CardSubtitle>وصف</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: "right" }}
                                        name="secondaryLang.description"
                                        label=""
                                        type="textarea"
                                        rows="7"
                                        value={
                                          groupDetails?.secondaryLang
                                            ?.description || ""
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  {/* <Card>
                    <CardBody>
                      <div className="form-group px-3">
                        <CardTitle className="mb-4">Banner images :</CardTitle>
                        {BannerImage?.length >= 1 && (
                          <Row className="mb-4">
                            <Col className="d-flex flex-wrap">
                              {BannerImage &&
                                BannerImage.map((img, key) => (
                                  <img
                                    key={key}
                                    src={img?.url}
                                    alt={img.url}
                                    className="mb-4"
                                    style={{
                                      marginRight: "1rem",
                                      height: "100px",
                                      width: "100px",
                                    }}
                                    width="100"
                                    height="100"
                                  />
                                ))}
                            </Col>
                          </Row>
                        )}

                        <div className="custom-file d-flex justify-content-between align-items-center">
                          <input
                            type="file"
                            name="banner_images"
                            className="custom-file-input"
                            id="banner_input"
                            onChange={onChangeImageHandler}
                            multiple
                            hidden
                            // value={groupDetails?.images[0]?.url}
                          />
                          <ImageInput htmlFor="banner_input" />

                          <div className="d-flex justify-content-end flex-wrap align-items-center">
                            {bannerImagesPreview?.map((img, index) => (
                              <div
                                style={{
                                  position: "relative",
                                }}
                                className="mb-2"
                                key={index}
                              >
                                <img
                                  src={img?.url}
                                  alt="Images Preview"
                                  className="mr-2"
                                  style={{ marginRight: ".5rem" }}
                                  width="75"
                                  height="72"
                                />
                                <i
                                  className="bx bx-x-circle"
                                  style={{
                                    color: "red",
                                    fontSize: "1.2rem",
                                    position: "absolute",
                                    top: "2px",
                                    right: "12px",
                                    background: "#e9e9ea",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => onDeleteImg(img?.id)}
                                ></i>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card> */}
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Label className="mb-2">Banner</Label>
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={bannerImagesPreview}
                        setImagesPreview={setBannerImagesPreview}
                        uploadUrl={"/categories"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-success save-customer"
                    >
                      {loading && (
                        <>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        </>
                      )}
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateGroups
