import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_DAILY_ORDER_LIST,
  GET_ORDER_SUMMARY,
  GET_SALE_DATA,
  GET_SALE_DATA_DOWNLOAD,
  GET_SALE_DATA_SUMMARY,
} from "./actionTypes"
import {
  getDailyOrderListSuccess,
  getDailyOrderListFail,
  getOrderSummarySuccess,
  getOrderSummaryFail,
  getSaleDataSuccess,
  getSaleDataFail,
  getSaleDataDownloadSuccess,
  getSaleDataDownloadFail,
  getSaleDataSummarySuccess,
  getSaleDataSummaryFail,
} from "./actions"
import { get, post } from "helpers/api_helper"
import { Notification } from "components/Common/Notification"

function getDailyOrderListAPi({
  page,
  limit,
  year,
  month,
  searchText,
  sort,
  sortByDate,
}) {
  if (sort) {
    return get(
      `/analytics/order/daily-list?page=${page ? page : 1}&sort=${sort}&limit=${
        limit ? limit : 10
      }
      &search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}&year=${
        year ? year : ""
      }&month=${month ? month : ""}`
    )
  } else {
    return get(
      `/analytics/order/daily-list?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}`
    )
  }
}

function getOrderSummaryAPi({ status }) {
  return get(
    `/analytics/order/order-summary/${status ? status : "Pickup Scheduled"}`
  )
}

function getSaleDataAPi({
  page,
  limit,
  year,
  month,
  searchText,
  sort,
  sortByDate,
}) {
  return get(
    `/analytics/sale/list?page=${page ? page : 1}&sort=${sort}&limit=${
      limit ? limit : 10
    }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}&year=${
      year ? year : ""
    }&month=${month ? month : ""}`
  )
}

function getSaleDataDownloadAPi({
  page,
  limit,
  year,
  month,
  searchText,
  sort,
  sortByDate,
}) {
  return get(
    `/analytics/sale/list/all?page=${page ? page : 1}&sort=${sort}&limit=${
      limit ? limit : 10
    }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}&year=${
      year ? year : ""
    }&month=${month ? month : ""}`
  )
}

function getSaleDataSummaryAPi({
  page,
  limit,
  year,
  month,
  searchText,
  sort,
  sortByDate,
}) {
  return get(
    `/analytics/sale/summary?page=${page ? page : 1}&sort=${sort}&limit=${
      limit ? limit : 10
    }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}&year=${
      year ? year : ""
    }&month=${month ? month : ""}`
  )
}

function* fetchDailyOrderList({ payload }) {
  try {
    const response = yield call(getDailyOrderListAPi, payload)
    yield put(getDailyOrderListSuccess(response))
  } catch (error) {
    yield put(getDailyOrderListFail(error))
  }
}

function* fetchOrderSummary({ payload }) {
  try {
    const response = yield call(getOrderSummaryAPi, payload)
    yield put(getOrderSummarySuccess(response))
  } catch (error) {
    yield put(getOrderSummaryFail(error))
  }
}

function* fetchSaleData({ payload }) {
  try {
    const response = yield call(getSaleDataAPi, payload)
    yield put(getSaleDataSuccess(response))
  } catch (error) {
    yield put(getSaleDataFail(error))
  }
}

function* fetchSaleDataDownload({ payload }) {
  try {
    const response = yield call(getSaleDataDownloadAPi, payload)
    yield put(getSaleDataDownloadSuccess(response))
  } catch (error) {
    yield put(getSaleDataDownloadFail(error))
  }
}

function* fetchSaleDataSummary({ payload }) {
  try {
    const response = yield call(getSaleDataSummaryAPi, payload)
    yield put(getSaleDataSummarySuccess(response))
  } catch (error) {
    yield put(getSaleDataSummaryFail(error))
  }
}

function* orderAnalyticsSaga() {
  yield takeEvery(GET_DAILY_ORDER_LIST, fetchDailyOrderList)
  yield takeEvery(GET_ORDER_SUMMARY, fetchOrderSummary)
  yield takeEvery(GET_SALE_DATA, fetchSaleData)
  yield takeEvery(GET_SALE_DATA_DOWNLOAD, fetchSaleDataDownload)
  yield takeEvery(GET_SALE_DATA_SUMMARY, fetchSaleDataSummary)
}

export default orderAnalyticsSaga
