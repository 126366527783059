import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  GET_ALL_CAMPAIGNS,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_ALL_CAMPAIGNS_FAIL,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  campaigns: [],
  campaignDetails: {},

  error: {},
  loading: false,
}

const Campaign = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_CAMPAIGN:
    case GET_ALL_CAMPAIGNS:
    case DELETE_CAMPAIGN:
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        loading: true,
      }

    // create campaign
    case CREATE_CAMPAIGN_FAIL:
    case GET_ALL_CAMPAIGNS_FAIL:
    case UPDATE_CAMPAIGN_FAIL:
    case DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // ..............................

    // create  Campaign
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          campaigns: [...state.campaigns.campaigns, action.payload],
        },
        error: {},
        loading: false,
      }
    // ALL Campaign
    case GET_ALL_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        error: {},
        loading: false,
      }
    // update
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        error: {},
        campaigns: {
          ...state.campaigns,
          campaigns: state.campaigns?.campaigns?.map(item =>
            item._id === action.payload._id ? { item, ...action.payload } : item
          ),
        },
        loading: false,
      }
    // delete
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          campaigns: state.campaigns?.campaigns?.filter(
            i => i._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Campaign
