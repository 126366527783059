import MetaTag from "components/Common/Meta-tag"
import React from "react"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Profile from "./Profile"
import PageView from "./PageView"
import Earnings from "./Earnings"

function PromoterDetails() {
  return (
    <div>
      <MetaTag title={"Promoter Details"} />
      <div className="page-content">
        <Breadcrumbs title="Promoters" breadcrumbItem=" Promoter Details" />
        <Container fluid>
          <div className="container-fluid">
            <Profile />
            <Row>
              <Col md={12}>
                <Earnings />
              </Col>
              <Col md={12}>
                <PageView />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PromoterDetails
