import { Col, Row } from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { debounce, map } from "lodash"
import PropTypes from "prop-types"

//actions
import { getRawUsers } from "store/actions"

import moment from "moment"
import { CouponDuration, couponTypeOption } from "./Create"

function Form({ formOption, updateData }) {
  const dispatch = useDispatch()
  const params = useParams()

  const { setSelectedUsers, couponDuration, setCouponDuration } = formOption

  const { users, userload } = useSelector(state => ({
    users: state.Users.rawUsers?.users,
    userload: state.Users.loading,
  }))

  const [selectedMulti, setselectedMulti] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [couponType, setCouponType] = useState("Custom Value")

  useEffect(() => {
    if (updateData?.durationType) {
      setCouponDuration(updateData?.durationType)
    }
  }, [updateData?.durationType])

  const optionGroup = users?.map(user => {
    return {
      value: user._id,
      label: `${user.phone}  ${user?.name ? `- ${user.name}` : ""}`,
    }
  })

  function handleMulti(userData) {
    setselectedMulti(userData)
    const arrangedUsers = userData?.map(a => a.value)
    setSelectedUsers(arrangedUsers)
  }

  const debouncUserSearch = debounce(value => setSearchText(value), 600)

  useEffect(() => {
    dispatch(getRawUsers(searchText))
  }, [dispatch, searchText])

  useEffect(() => {
    const userList = updateData?.whitelistUsersList

    const newList = map(userList, user => ({
      ...selectedMulti,
      label: `${user.phone}  ${user?.name ? `- ${user.name}` : ""}`,
      value: user?._id,
    }))

    setselectedMulti(newList)

    const idList = map(userList, i => i?._id)

    setSelectedUsers(idList)
  }, [updateData?.whitelistUsersList])

  useEffect(() => {
    if (updateData?.couponType) {
      setCouponType(updateData?.couponType)
    }
  }, [updateData?.couponType])

  return (
    <Row xl="12">
      <Col xl="12">
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Update Coupon :</CardTitle>
            <Row>
              <Col xl="4" lg="4" md="6" sm="12">
                <CardTitle className="h4 mb-4 text_deals_green">Name</CardTitle>
                <div className="mb-3">
                  <AvField
                    name="name"
                    value={updateData?.name ?? ""}
                    required
                    label=""
                    type="text"
                  />
                </div>
              </Col>

              <Col xl="4" lg="4" md="6" sm="12">
                <CardTitle className="h4 mb-4 text_deals_green">
                  Coupon Code
                </CardTitle>
                <div className="mb-3">
                  <AvField
                    value={updateData?.couponCode ?? ""}
                    name="couponCode"
                    required
                    label=""
                    type="text"
                  />
                </div>
              </Col>

              <Col xl="4" lg="4" md="6" sm="12">
                <CardTitle className="h4 mb-4 text_deals_green">
                  Coupon Type
                </CardTitle>
                <AvField
                  type="select"
                  name="couponType"
                  value={updateData?.couponType ?? ""}
                  required
                  onChange={e => setCouponType(e.target.value)}
                >
                  <option>Select a Coupon Type</option>
                  {couponTypeOption?.map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </AvField>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <Col xl="12">
        <Card>
          <Row>
            <Col xl="6" md="12">
              <CardBody>
                <CardTitle className="h4 mb-4 text_deals_green">
                  Duration
                </CardTitle>
                <Row>
                  <div
                    className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    {map(CouponDuration, (item, key) => (
                      <div key={key} className="me-3">
                        <input
                          type="radio"
                          className="btn-check"
                          name="durationType"
                          id={item.id}
                          autoComplete="off"
                          value={item.value}
                          checked={item.value === couponDuration}
                          onChange={e => setCouponDuration(e.target.value)}
                        />
                        <label
                          className="btn btn-outline-info"
                          htmlFor={item.id}
                        >
                          {item.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </Row>
              </CardBody>
            </Col>
            {couponDuration === "Full time" ? (
              <></>
            ) : (
              <Col xl="6" md="12">
                <CardBody>
                  <Row>
                    <Col md="6" sm="12">
                      <CardTitle className="h4 mb-4 text_deals_green">
                        Falid From
                      </CardTitle>
                      <div className="mb-3">
                        <AvField
                          name="validFrom"
                          label=""
                          type="date"
                          value={moment(updateData?.validFrom).format(
                            "yyyy-MM-DD"
                          )}
                        />
                      </div>
                    </Col>

                    {couponDuration === "Start time" ? (
                      <></>
                    ) : (
                      <Col md="6" sm="12">
                        <CardTitle
                          className="h4 mb-4 text_deals_green 
                      "
                        >
                          Valid To
                        </CardTitle>
                        <div className="mb-3">
                          <AvField
                            name="validTo"
                            value={moment(updateData?.validTo).format(
                              "yyyy-MM-DD"
                            )}
                            label=""
                            type="date"
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Col>
            )}
          </Row>
        </Card>
      </Col>

      <Col xl="12">
        <Card>
          <CardBody>
            <Row>
              {couponType === "Custom Percentage" && (
                <Col xl="4" lg="4" md="6" sm="12">
                  <CardTitle className="h4 mb-4 text_deals_green">
                    Discount Percentage
                  </CardTitle>
                  <div className="mb-3">
                    <AvField
                      name="discountPercentage"
                      label=""
                      type="number"
                      value={updateData?.discountPercentage ?? ""}
                    />
                  </div>
                </Col>
              )}

              {couponType === "Custom Value" && (
                <Col xl="4" lg="4" md="6" sm="12">
                  <CardTitle
                    className="h4 mb-4 text_deals_green 
              "
                  >
                    Discount Value
                  </CardTitle>
                  <div className="mb-3">
                    <AvField
                      name="discountValue"
                      label=""
                      type="number"
                      value={updateData?.discountValue ?? ""}
                    />
                  </div>
                </Col>
              )}

              <Col xl="4" lg="4" md="6" sm="12">
                <CardTitle className="h4 mb-4 text_deals_green">
                  Max Value
                </CardTitle>
                <div className="mb-3">
                  <AvField
                    name="maxValue"
                    value={updateData?.maxValue ?? ""}
                    label=""
                    type="number"
                  />
                </div>
              </Col>
              <Col xl="4" lg="4" md="6" sm="12">
                <CardTitle className="h4 mb-4 text_deals_green">
                  Min Value
                </CardTitle>
                <div className="mb-3">
                  <AvField
                    name="minOrderValue"
                    value={updateData?.minOrderValue ?? ""}
                    label=""
                    type="number"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <Col xl="12">
        <Card>
          <CardBody>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" className="mb-3">
                <CardTitle className="h4 mb-2 text_deals_green">
                  Whitelist Users
                </CardTitle>
                <div className="mb-3">
                  <Select
                    value={selectedMulti}
                    isMulti={true}
                    onChange={e => {
                      handleMulti(e)
                    }}
                    options={optionGroup}
                    isLoading={userload}
                    classNamePrefix="select2-selection"
                    onInputChange={debouncUserSearch}
                  />
                </div>
              </Col>

              <Col xl="4" lg="6" md="6" sm="12">
                <CardTitle className="h4 mb-4 text_deals_green">
                  {/* Maximum no.of People per Coupon */}
                  Total user limit
                </CardTitle>
                <div className="mb-3">
                  <AvField
                    name="totalUserLimit"
                    value={updateData?.totalUserLimit ?? ""}
                    label=""
                    type="phone"
                  />
                </div>
              </Col>

              <Col xl="4" lg="6" md="6" sm="12">
                <CardTitle
                  className="h4 mb-4 text_deals_green 
              "
                >
                  Per user limit
                </CardTitle>
                <div className="mb-3">
                  <AvField
                    name="perUserLimit"
                    label=""
                    type="phone"
                    value={updateData?.perUserLimit ?? ""}
                  />
                </div>
              </Col>

              {/* <Col xl="4" lg="4" md="6" sm="12">
            <CardTitle
              className="h4 mb-4 text_deals_green"

              
            >
              Deduction Count
            </CardTitle>
            <div className="mb-3">
              <AvField
                name="deductionCount"
                label=""
                type="number"
              />
            </div>
          </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Form

Form.propTypes = {
  formOption: PropTypes.object,
  updateData: PropTypes.object,
}
