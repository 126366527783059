import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ALL_CRMS } from "./actionTypes"
import { getAllCrmsSuccess, getAllCrmsFail } from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// all Campaign

function getAllCrmsAPi({
  page,
  sort,
  limit,
  searchText,
  medium,
  communication,
  transaction,
  campaign,
  order,
  user,
}) {
  // sort=${sort ? sort : ""}
  return get(
    `/communication/admin/all?page=${page ? page : 1}&sort=${
      sort ? sort : ""
    }&limit=${limit ? limit : 10}&search=${
      searchText ? searchText : ""
    }&medium=${medium ? medium : ""}&communication=${
      communication ? communication : ""
    }&transaction=${transaction ? transaction : ""}&campaign=${
      campaign ? campaign : ""
    }&order=${order ? order : ""}&user=${user ? user : ""}`
  )
}

// all Campaign
function* fetchAllCrms({ payload }) {
  try {
    const response = yield call(getAllCrmsAPi, payload)
    yield put(getAllCrmsSuccess(response))
  } catch (error) {
    yield put(getAllCrmsFail(error))
  }
}

function* crmsSaga() {
  yield takeEvery(GET_ALL_CRMS, fetchAllCrms)
}

export default crmsSaga

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
