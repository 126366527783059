import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  CREATE_CAMPAIGN,
  GET_ALL_CAMPAIGNS,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
} from "./actionTypes"
import {
  createCampaignFail,
  createCampaignSuccess,
  getAllCampaignsSuccess,
  getAllCampaignsFail,
  updateCampaignSuccess,
  updateCampaignFail,
  deleteCampaignSuccess,
  deleteCampaignFail,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"
// create campaign
const createCampaignApi = ({ campaign }) => {
  return post("/campaign/admin/new", campaign)
}
// all Campaign

function getAllCampaignsAPi({ page, sort, limit, searchText, medium, status }) {
  // sort=${sort ? sort : ""}
  return get(
    `/campaign/admin/all?page=${page ? page : 1}&campaignType=${
      sort ? sort : ""
    }&limit=${limit ? limit : 10}&search=${
      searchText ? searchText : ""
    }&medium=${medium ? medium : ""}&status=${status ? status : ""}`
  )
}
// update campngn
const updateCampaignApi = ({ campaignId, campaign }) => {
  return ApiPut(`/campaign/admin/${campaignId}`, campaign)
}
// delete cmpgn
const deleteCampaignApi = campaignId => {
  return del(`/campaign/admin/${campaignId}`)
}

// create Campaign

function* onCreateCampaign({ payload }) {
  try {
    const response = yield call(createCampaignApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createCampaignSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
      doneNotification("Campaign Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createCampaignFail(error))
  }
}
// all Campaign
function* fetchAllCampaigns({ payload }) {
  try {
    const response = yield call(getAllCampaignsAPi, payload)
    yield put(getAllCampaignsSuccess(response))
  } catch (error) {
    yield put(getAllCampaignsFail(error))
  }
}
// update

function* onUpdateCampaign({ payload }) {
  try {
    const response = yield call(updateCampaignApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Campaign Updated Successfully!",
        title: "",
      })
      yield put(updateCampaignSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateCampaignFail(error.response.data))
  }
}
// delete cmpgn
function* onDeleteCampaign({ campaignId, history }) {
  try {
    const response = yield call(deleteCampaignApi, campaignId)
    yield put(deleteCampaignSuccess(response))
    Notification({
      type: "error",
      message: "campaign Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteCampaignFail(error))
  }
}

function* campaignSaga() {
  yield takeEvery(CREATE_CAMPAIGN, onCreateCampaign)
  yield takeEvery(GET_ALL_CAMPAIGNS, fetchAllCampaigns)
  yield takeEvery(UPDATE_CAMPAIGN, onUpdateCampaign)
  yield takeEvery(DELETE_CAMPAIGN, onDeleteCampaign)
}

export default campaignSaga

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
