import {
  GET_ALL_CRMS,
  GET_ALL_CRMS_SUCCESS,
  GET_ALL_CRMS_FAIL,
} from "./actionTypes"

// all CRMS
export const getAllCrms = (
  page,
  sort,
  limit,
  searchText,
  medium,
  communication,
  transaction,
  campaign,
  order,
  user
) => ({
  type: GET_ALL_CRMS,
  payload: {
    page,
    sort,
    limit,
    searchText,
    medium,
    communication,
    transaction,
    campaign,
    order,
    user,
  },
})

export const getAllCrmsSuccess = data => ({
  type: GET_ALL_CRMS_SUCCESS,
  payload: data,
})

export const getAllCrmsFail = error => ({
  type: GET_ALL_CRMS_FAIL,
  payload: error,
})
