import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { useDispatch, useSelector } from "react-redux"
import { BiEdit } from "react-icons/bi"
import { CSVLink } from "react-csv"
var xlsx = require("xlsx")

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "components/Common/MyPagination"

//actions
import {
  getSaleData,
  getSaleDataSummary,
} from "store/analytics/orderAnalytics/actions"

import "assets/scss/datatables.scss"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useSiteVersion } from "hooks/useSiteVersion"
import { CurrencySwitch } from "hooks/Currency"
import { FaFileInvoice, FaList, FaStickyNote } from "react-icons/fa"
import ReactDrawer from "react-drawer"
import { RiTableFill } from "react-icons/ri"
import { NotificationAsync } from "components/Common/Notification"

const SaleData = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const { isPremium } = useSiteVersion()
  const {
    saleData,
    saleDataTotal,
    saleDataLoading,
    saleDataSummary,
    saleDataSummaryLoading,
  } = useSelector(state => ({
    saleData: state?.OrderAnalytics?.saleData,
    saleDataTotal: state?.OrderAnalytics?.saleDataTotal,
    saleDataLoading: state?.OrderAnalytics?.saleDataLoading,

    saleDataSummary: state?.OrderAnalytics?.saleDataSummary,
    saleDataSummaryLoading: state?.OrderAnalytics?.saleDataSummaryLoading,
  }))

  const [viewLoad, setViewLoad] = useState({
    status: false,
    id: "",
  })
  const [orderSearchText, setOrderSearchText] = useState("")
  const [showModal, setShowModal] = useState({ status: "", data: "" })

  const openModal = item => {
    setShowModal({ status: true, data: item })
  }
  const HandleCloseModal = () => {
    setShowModal({ status: false, data: "" })
  }

  const [sort, setSort] = useState("All")
  const [isCustomStore, setIsCustomStore] = useState(false)

  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  const [excelDownloadList, setExcelDownloadList] = useState([])
  const [headers, setHeaders] = useState([])

  //pagination
  const [totalItemLimit, setTotalItemLimit] = useState(30)
  const [year, setYear] = useState(moment().year())
  const [month, setMonth] = useState(moment().month() + 1)
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(saleDataTotal / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const getDaysInSpecificMonth = () => {
    const now = moment()
    const currentYear = now.year()
    const currentMonth = now.month() + 1
    const currentDay = now.date()

    let daysPassed

    if (year === currentYear && month === currentMonth) {
      daysPassed = currentDay
    } else {
      daysPassed = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()
      if (now.isBefore(moment(`${year}-${month}`, "YYYY-MM"))) {
        daysPassed = 0
      }
    }

    // return moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    return daysPassed
  }

  const handleDateSearch = () => {
    if (
      finalDateFormated?.from?.length >= 1 &&
      finalDateFormated?.to?.length >= 1
    ) {
      return finalDateFormated
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }

  useEffect(() => {
    dispatch(
      getSaleData(
        page,
        totalItemLimit,
        year,
        month,
        orderSearchText,
        sort,
        handleDateSearch()
      )
    )
  }, [
    dispatch,
    page,
    year,
    month,
    orderSearchText,
    totalItemLimit,
    sort,
    isCustomStore,
    finalDateFormated,
  ])

  useEffect(() => {
    dispatch(
      getSaleDataSummary(
        page,
        totalItemLimit,
        year,
        month,
        orderSearchText,
        sort,
        handleDateSearch()
      )
    )
  }, [
    dispatch,
    page,
    year,
    month,
    orderSearchText,
    totalItemLimit,
    sort,
    isCustomStore,
    finalDateFormated,
  ])

  useEffect(() => {
    if (saleData) {
      const excelDownloadList = map(saleData, (item, key) => ({
        ...item,
        date: `${moment(item?.date).format("DD/MM/YYYY - dddd")}`,
        onlineOrdersCount: item?.onlineOrdersCount,
        codOrdersCount: item?.codOrdersCount,
        totalOrdersCount: item?.totalOrdersCount,
        totalOrderValue: `₹ ${item?.totalOrderValue}`,
      }))
      setExcelDownloadList(excelDownloadList)
    }
    const headers = [
      { label: "Date", key: "date" },
      { label: "Online", key: "onlineOrdersCount" },
      { label: "COD", key: "codOrdersCount" },
      { label: "Total", key: "totalOrdersCount" },
      { label: "Value", key: "totalOrderValue" },
    ]
    setHeaders(headers)
  }, [saleData])

  // useEffect(() => {
  //   dispatch(getTotalOrderCounts(orderSearchText, handleDateSearch()))
  // }, [dispatch, orderSearchText, finalDateFormated])

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "orderId",
      text: "Order ID",
      sort: true,
    },
    {
      dataField: "orderValue",
      text: "Value",
      sort: true,
    },
    {
      dataField: "revenue",
      text: "Revenue",
      sort: true,
    },
    {
      dataField: "cogs",
      text: "Cost",
      sort: true,
    },
    {
      dataField: "grossProfit",
      text: "Gross",
      sort: true,
    },
    {
      dataField: "shippingCost",
      text: "Shipping",
      sort: true,
    },
    {
      dataField: "taxAmount",
      text: "Tax",
      sort: true,
    },
    {
      dataField: "netProfit",
      text: "Net Profit",
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ]

  const handleOrderStatus = status => {
    switch (status) {
      case "Pending":
        return "dark"
      case "Processing":
        return "info"
      case "Shipped":
        return "info"
      case "Delivered":
        return "success"
      case "Cancelled":
        return "warning"
      case "Returned":
        return "danger"
      case "Fulfilled":
        return "success"
      case "Partially Fulfilled":
        return "success"
      default:
        return "info"
    }
  }

  const currency = CurrencySwitch()

  const handleViewClick = item => {
    setViewLoad({
      status: true,
      id: item?._id,
    })
    dispatch(clearOrders())
    setTimeout(() => {
      history.push(`/order/${item?._id}`)
    }, 500)
  }

  const data = map(saleData, item => ({
    ...item,
    key: item.order,
    orderId: <Link to={`/order/${item?._id}`}>{item?.orderId}</Link>,
    totalOrderValue: `${currency} ${item?.totalOrderValue}`,
    date: moment(item?.date).format("DD/MM/YYYY - dddd"),
    lossValue: (
      <span style={{ color: "red" }}>{`- ${currency} ${item?.lossValue}`}</span>
    ),
    effectiveOrderValue: (
      <span
        style={{ color: "green", fontWeight: "bold" }}
      >{`${currency} ${item?.effectiveOrderValue}`}</span>
    ),
    totalOrdersCount: (
      <span style={{ fontWeight: "bold" }}>{item?.totalOrdersCount}</span>
    ),
    netProfit:
      item?.netProfit < 0 ? (
        <span style={{ color: "red", fontWeight: "bold" }}>
          {item?.netProfit}
        </span>
      ) : (
        <span style={{ color: "green", fontWeight: "bold" }}>
          {item?.netProfit}
        </span>
      ),
    // paymentType: (
    //   <>
    //     {item?.paymentType === "Cash on Delivery" ? (
    //       <Badge className="px-2 py-2 badge-soft-info ">COD</Badge>
    //     ) : (
    //       <Badge className="px-2 py-2 badge-soft-success ">
    //         {item?.paymentType}
    //       </Badge>
    //     )}
    //     &nbsp;
    //     {item?.isPaid ? (
    //       <Badge className="px-2 py-2 badge-soft-success ">Paid</Badge>
    //     ) : (
    //       <Badge className="px-2 py-2 badge-soft-danger">Not Paid</Badge>
    //     )}
    //   </>
    // ),
    // orderStatus: (
    //   <Badge
    //     className={
    //       "px-2 py-2 badge-soft-" + `${handleOrderStatus(item?.orderStatus)}`
    //     }
    //   >
    //     {" "}
    //     {item?.orderStatus}{" "}
    //   </Badge>
    // ),
    // activeData: (
    //   <>
    //     <div className="form-check form-switch form-switch-md mb-3">
    //       <input
    //         type="checkbox"
    //         className="form-check-input"
    //         id="customSwitchsizemd"
    //         onChange={() => {
    //           handleSwitchChange(item?._id)
    //         }}
    //         checked={item?.isActive}
    //       />
    //       <label className="form-check-label" htmlFor="customSwitchsizemd">
    //         {item?.isActive ? `Active` : `Inactive`}
    //       </label>
    //     </div>
    //   </>
    // ),
    // action: (
    //   <div>
    //     <Button
    //       onClick={() => openModal(item)}
    //       id={`view`}
    //       type="button"
    //       color="white"
    //       className="ms-1 btn"
    //     >
    //       {viewLoad.status && viewLoad.id === item?._id ? (
    //         <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
    //       ) : (
    //         //   <i
    //         //   className="bx bx-show-alt me-2"
    //         //    style={{ color: "green", fontSize: "22px" }}
    //         //  />
    //         //  <Link to={`/orders/update/${item?._id}`}>
    //         <BiEdit />
    //         // </Link>
    //       )}
    //     </Button>
    //   </div>
    // ),
  }))

  const debounceOrderSearch = debounce(value => setOrderSearchText(value), 600)

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const handleSwitchChange = (e, id) => {
    // TODO: API needed for status change
  }

  useEffect(() => {
    const currentLimit = localStorage.getItem("orderLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("orderLimit", e.target.value)
    }
  }

  const handleYear = e => {
    if (e.target.value >= 1) {
      setYear(parseInt(e.target.value))
    }
  }

  const handleMonth = e => {
    if (e.target.value >= 1) {
      setMonth(parseInt(e.target.value))
    }
  }

  const listChange = sortValue => {
    setSort(sortValue)

    history.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
  }

  useEffect(() => {
    if (location?.state && location?.state?.sort) {
      setSort(location?.state?.sort)
    } else {
      // setSort("all")
    }
  }, [location?.state?.sort])

  //shiprocket
  const [selectedItemIds, setSelectedItemIds] = useState([])
  const [selectedShipmentLabelIds, setSelectedShipmentLabelIds] = useState([])
  const [selectedShipmentInvoiceIds, setSelectedShipmentInvoiceIds] = useState(
    []
  )

  // select row
  const handleSelectTableRow = () => {
    return {
      mode: "checkbox",
      onSelect: (row, isSelect) => {
        if (isSelect) {
          setSelectedItemIds([...selectedItemIds, row._id])
          const currentItem = data?.filter(i => i._id === row._id)[0]

          //label
          if (
            selectedShipmentLabelIds?.includes(
              currentItem?.shiprocketOrderDetails?.shipment_id
            )
          ) {
            setSelectedShipmentLabelIds(
              selectedShipmentLabelIds?.filter(
                id => id !== currentItem?.shiprocketOrderDetails?.shipment_id
              )
            )
          } else {
            setSelectedShipmentLabelIds([
              ...selectedShipmentLabelIds,
              currentItem?.shiprocketOrderDetails?.shipment_id,
            ])
          }

          //invoice
          if (
            selectedShipmentInvoiceIds?.includes(
              currentItem?.shiprocketOrderDetails?.order_id
            )
          ) {
            setSelectedShipmentInvoiceIds(
              selectedShipmentInvoiceIds?.filter(
                id => id !== currentItem?.shiprocketOrderDetails?.order_id
              )
            )
          } else {
            setSelectedShipmentInvoiceIds([
              ...selectedShipmentInvoiceIds,
              currentItem?.shiprocketOrderDetails?.order_id,
            ])
          }
        } else {
          setSelectedItemIds(selectedItemIds?.filter(id => id !== row._id))
          const currentItem = data?.filter(i => i._id === row._id)[0]

          //label
          setSelectedShipmentLabelIds(
            selectedShipmentLabelIds?.filter(
              id => id !== currentItem?.shiprocketOrderDetails?.shipment_id
            )
          )
          //invoice
          setSelectedShipmentInvoiceIds(
            selectedShipmentInvoiceIds?.filter(
              id => id !== currentItem?.shiprocketOrderDetails?.order_id
            )
          )
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect === true) {
          //label
          const selectedShipmentLabelIds = rows.map(
            row => row?.shiprocketOrderDetails?.shipment_id
          )
          setSelectedShipmentLabelIds(selectedShipmentLabelIds)
          //invoice
          const selectedShipmentInvoiceIds = rows.map(
            row => row?.shiprocketOrderDetails?.order_id
          )
          setSelectedShipmentInvoiceIds(selectedShipmentInvoiceIds)

          const selectedItemIds = rows?.map(i => i._id)
          setSelectedItemIds(selectedItemIds)
        } else {
          setSelectedShipmentLabelIds([])
          setSelectedItemIds([])
          setSelectedShipmentInvoiceIds([])
        }
      },
      selected: selectedItemIds,
    }
  }

  const [layout, setLayout] = useState("table")
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState("right")

  const onDrawerClose = () => {
    setOpen(false)
  }
  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
    setLayout("list")
    localStorage.setItem("layout", "list")
  }

  useEffect(() => {
    const newLayout = localStorage.getItem("layout")
    if (newLayout && newLayout !== undefined) {
      setLayout(newLayout || "table")
    }
  }, [])

  const [isOpened, setIsOpened] = useState(false)

  const headerOptions = (
    <div
      className={`headerOptionWrap d-flex flex-wrap align-items-center justify-content-between`}
    >
      <div className="headerOptionSubWrap d-flex align-items-center flex-wrap">
        {/* <div className="search-box ms-2 d-inline-block mb-3">
          <div className="position-relative">
            <AvForm>
              <AvField
                name="searchText"
                placeholder="Search"
                type="text"
                onChange={e => debounceOrderSearch(e.target.value)}
              />
            </AvForm>
            <i className="bx bx-search-alt search-icon" />
          </div>
        </div> */}

        {/* <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
          <div className="mx-1">
            <span className="">
              Show
              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
            </span>
          </div>
          <div className="" style={{ width: "50px" }}>
            <AvField
              name="pageLimit"
              className="form-control"
              value={totalItemLimit}
              onChange={e => handlePageLimit(e)}
              type="text"
              required
            />
          </div>
        </AvForm> */}
        <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
          {/* <i className="fas fa-calendar mx-1"></i> */}
          <div className="mx-1">
            <span className="">Year:</span>
          </div>
          <div className="" style={{ width: "60px" }}>
            <AvField
              name="year"
              className="form-control"
              value={year}
              onChange={e => handleYear(e)}
              type="text"
              required
            />
          </div>
          {/* <i className="fas fa-calendar mx-1"></i> */}
          <div className="mx-1">
            <span className="">Month:</span>
          </div>
          <div className="" style={{ width: "40px" }}>
            <AvField
              name="month"
              className="form-control"
              value={month}
              onChange={e => handleMonth(e)}
              type="text"
              required
            />
          </div>
        </AvForm>

        <div className="d-flex sortWrapper mx-2 me-3 mb-3 align-items-center">
          <AvForm>
            <div className="d-flex date_wrapper align-items-center">
              <p className="mb-0 pb-0 mx-1" style={{ whiteSpace: "nowrap" }}>
                From :
              </p>
              <div className="d-flex align-items-center position-relative">
                <AvField
                  name="dateRange"
                  type="date"
                  validate={{
                    dateRange: {
                      start: { value: -5, units: "years" },
                      end: { value: 5, units: "years" },
                    },
                  }}
                  onChange={e =>
                    setFinalDateFormated({
                      ...finalDateFormated,
                      from: e.target.value,
                    })
                  }
                  value={finalDateFormated?.from}
                />
                <button
                  onClick={() =>
                    setFinalDateFormated({
                      ...finalDateFormated,
                      from: "",
                    })
                  }
                  type="button"
                  className="btn btn-white editable-cancel date_close btn-sm"
                >
                  <i className="mdi mdi-close text-danger "></i>
                </button>
              </div>

              <p className="mb-0 pb-0 mx-1" style={{ whiteSpace: "nowrap" }}>
                To :
              </p>

              <div className="d-flex align-items-center position-relative">
                <AvField
                  name="dateRange"
                  className="mx-1"
                  type="date"
                  validate={{
                    dateRange: {
                      start: { value: -5, units: "years" },
                      end: { value: 5, units: "years" },
                    },
                  }}
                  onChange={e =>
                    setFinalDateFormated({
                      ...finalDateFormated,
                      to: e.target.value,
                    })
                  }
                  value={finalDateFormated?.to}
                />
                <button
                  onClick={() =>
                    setFinalDateFormated({
                      ...finalDateFormated,
                      to: "",
                    })
                  }
                  type="button"
                  className="btn btn-white editable-cancel date_close btn-sm"
                >
                  <i className="mdi mdi-close text-danger "></i>
                </button>
              </div>
            </div>
          </AvForm>
          <Button
            data-tip
            data-for="downloadTip"
            style={{ width: "120px", border: "none" }}
            className="w-md btn btn-info m-auto me-0 mt-0 mb-2 d-block bg-white"
          >
            {saleData && saleData?.length > 0 && (
              <CSVLink
                data={excelDownloadList}
                headers={headers}
                filename={"saleData.csv"}
              >
                <i
                  className="bx bx-download mx-1"
                  style={{
                    fontSize: "18px",
                    color: "blue",
                  }}
                />
                {`Download`}
              </CSVLink>
            )}
          </Button>
        </div>

        {(sort === "Processing" || sort === "Pickup Scheduled") && (
          <>
            {/* {selectedShipmentLabelIds?.length >= 1 && (
              <Button
                className="w-md btn btn-warning me-2 shippingBtn ms-2 mb-3"
                onClick={handleDownloadShippingLabel}
              >
                <FaStickyNote className="me-2" />
                Shipment Label ({selectedShipmentLabelIds?.length})
                {shiprocketLoad && (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                )}
              </Button>
            )} */}
            {/* {selectedShipmentInvoiceIds?.length >= 1 && (
              <Button
                className="w-md btn btn-warning shippingBtn me-0 ms-2 mb-3"
                onClick={handleDownloadShippingInvoice}
              >
                <FaFileInvoice className="me-2" />
                Order Invoice ({selectedShipmentInvoiceIds?.length})
                {shiprocketInvoiceLoad && (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                )}
              </Button>
            )} */}
          </>
        )}
        {/* {(sort === "Shipped" ||
          sort === "Processing" ||
          sort === "Pickup Scheduled") && (
          <button
            onClick={() => dispatch(autoUpdateOrderStatus(sort))}
            className="btn btn-outline-success ms-2 statusBtn mb-3"
          >
            Auto Update Status
            {autoUpdateLoad && (
              <>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
              </>
            )}
          </button>
        )} */}
        {/* <Link
          to="/orders/new"
          className="w-md btn newOrderBtn btn-light ms-2 d-block mb-3"
        >
          + New Order
        </Link> */}
      </div>
    </div>
  )

  // useEffect(() => {
  //   if (autoUpdateStatus?.length >= 1) {
  //     setIsOpened(true)
  //     dispatch(getTotalOrderCounts(orderSearchText, handleDateSearch()))
  //     dispatch(
  //       getOrders(
  //         page,
  //         totalItemLimit,
  //         orderSearchText,
  //         sort,
  //         handleDateSearch()
  //       )
  //     )
  //   } else {
  //     setIsOpened(false)
  //   }
  // }, [autoUpdateStatus])

  const handleCloseStatusPop = () => {
    setIsOpened(false)
    dispatch(clearAutoStatusUpdate())
  }

  // const handleExcelDownloadList = () => {
  //   const excelDownloadList = map(
  //     dailyOrderList,
  //     (item, key) => ({
  //       ...item,
  //       date: `${moment(item?.date).format("DD/MM/YYYY - dddd")}`,
  //       onlineOrdersCount: item?.onlineOrdersCount,
  //       codOrdersCount: item?.codOrdersCount,
  //       totalOrdersCount: item?.totalOrdersCount,
  //       totalOrderValue: `${currency} ${item?.totalOrderValue}`,
  //     })
  //   )
  //   setExcelDownloadList(excelDownloadList);
  // }

  // const excelDownloadList = map(
  //   dailyOrderList,
  //   (item, key) => ({
  //     ...item,
  //     // date: `${moment(item?.date).format("DD/MM/YYYY - dddd")}`,
  //     onlineOrdersCount: item?.onlineOrdersCount,
  //     codOrdersCount: item?.codOrdersCount,
  //     totalOrdersCount: item?.totalOrdersCount,
  //     // totalOrderValue: `${currency} ${item?.totalOrderValue}`,
  //   })
  // )

  return (
    <React.Fragment>
      {/* {autoUpdateLoad && (
        <NotificationAsync
          timeOut={20000}
          title={"Order Status Update"}
          message={"The status of all shipping orders is updating..."}
        />
      )} */}

      <Row>
        <Col className="col-md-12 col-lg-12 col-xl-12">
          <Card className="pt-3">
            <CardBody>
              <ToolkitProvider
                keyField="date"
                columns={columns}
                data={data}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      {isPremium && (
                        <>
                          {/* <Col
                            className={`mb-${
                              layout === "table" ? "2" : "0"
                            } col-10`}
                          >
                            <div
                              className="btn-group mb-2 mt-xl-0"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              {map(tableHeads, (head, key) => (
                                <Fragment key={key}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnradio"
                                    id={head.value + key}
                                    autoComplete="off"
                                    checked={sort === head.value}
                                    onChange={() => listChange(head?.value)}
                                  />
                                  <label
                                    className="btn btn-success d-flex align-items-center w-100"
                                    htmlFor={head.value + key}
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {head?.title}

                                    <Badge
                                      className={`font-size-12 ms-2 ${
                                        sort === head.value
                                          ? "badge-soft-warning"
                                          : "badge-soft-light"
                                      }`}
                                      pill
                                    >
                                      {head?.count}
                                    </Badge>
                                  </label>
                                </Fragment>
                              ))}
                            </div>
                          </Col> */}
                          {/* <Col className="col-2 d-flex align-items-start">
                            <div>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={"table-view-option"}
                              >
                                Table view
                              </UncontrolledTooltip>
                              <Button
                                color={layout === "table" ? "warning" : "light"}
                                className="btn-rounded ms-4 "
                                id="table-view-option"
                                onClick={() => {
                                  setLayout("table")
                                  localStorage.setItem("layout", "table")
                                }}
                              >
                                <RiTableFill />
                              </Button>
                            </div>
                            <div>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={"list-view-option"}
                              >
                                List view
                              </UncontrolledTooltip>
                              <Button
                                color={layout === "list" ? "warning" : "light"}
                                className="btn-rounded ms-2 position-relative"
                                id="list-view-option"
                                onClick={toggleTopDrawer}
                              >
                                <FaList />
                                {selectedShipmentInvoiceIds?.length ||
                                selectedShipmentLabelIds?.length ? (
                                  <span
                                    className="btn-rounded bg-danger font-size-10 position-absolute"
                                    style={{
                                      top: -6,
                                      right: -6,
                                      padding: 1,
                                      height: 20,
                                      width: 20,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {selectedShipmentInvoiceIds?.length ||
                                      selectedShipmentLabelIds?.length}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Button>
                            </div>
                          </Col> */}
                        </>
                      )}
                      {layout === "table" && (
                        <div className="order-option-table">
                          {headerOptions}
                        </div>
                      )}
                    </Row>
                    {layout === "table" && <hr />}
                    {saleDataLoading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {saleData?.length ? (
                          <Row>
                            {saleDataSummaryLoading ? (
                              <Spinner
                                color="secondary"
                                className="d-block m-auto"
                              />
                            ) : (
                              <Row>
                                <Col lg="6" md="12">
                                  <p>
                                    Total Revenue : ₹&nbsp;
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {saleDataSummary?.totalRevenue
                                        ? saleDataSummary?.totalRevenue
                                        : 0}{" "}
                                    </span>
                                    <br /> Avg. Daily Value : ₹&nbsp;
                                    {saleDataSummary?.totalRevenue
                                      ? (
                                          saleDataSummary?.totalRevenue /
                                          getDaysInSpecificMonth()
                                        )?.toFixed(2)
                                      : 0}{" "}
                                    <br />
                                    Total COGS : &nbsp;
                                    <span style={{ color: "red" }}>
                                      {saleDataSummary?.totalCogs
                                        ? `-₹${saleDataSummary?.totalCogs}`
                                        : 0}
                                    </span>
                                    <br />
                                    Total Gross Profit : &nbsp;
                                    <span style={{ color: "green" }}>
                                      {saleDataSummary?.totalGrossProfit
                                        ? `₹${saleDataSummary?.totalGrossProfit}`
                                        : 0}
                                    </span>
                                  </p>
                                </Col>
                                <Col lg="6" md="12">
                                  <p>
                                    Total Count : &nbsp;
                                    {saleDataSummary?.totalSaleCount
                                      ? saleDataSummary?.totalSaleCount
                                      : 0}{" "}
                                    <br />
                                    Total Shipping Cost : &nbsp;
                                    <span style={{ color: "red" }}>
                                      {saleDataSummary?.totalShippingCost
                                        ? `-₹${saleDataSummary?.totalShippingCost}`
                                        : 0}
                                    </span>
                                    <br />
                                    Total Tax Amount : &nbsp;
                                    <span style={{ color: "red" }}>
                                      {saleDataSummary?.totalTaxAmount
                                        ? `-₹${saleDataSummary?.totalTaxAmount}`
                                        : 0}
                                    </span>
                                    <br />
                                    Total Net Profit : &nbsp;
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {saleDataSummary?.totalNetProfit
                                        ? `₹${saleDataSummary?.totalNetProfit}`
                                        : 0}{" "}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            )}
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"order"}
                                  key={"order"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  // defaultSorted={defaultSorted}
                                  selectRow={handleSelectTableRow()}
                                  classes={
                                    "table align-middle table-nowrap custom-padding table-striped"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  // rowClasses={"table-info"}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <p className="my-2 mx-3 ">No Data!</p>
                        )}
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <SimpleBar style={{ height: "900px", boxShadow: "none" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={onDrawerClose}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <p className="m-0">More Options</p>
            </div>
            <hr className="my-0" />
            <div className="order-option-sidebar">{headerOptions}</div>
          </div>
        </SimpleBar>
      </ReactDrawer>
    </React.Fragment>
  )
}

export default SaleData
