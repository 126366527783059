import {
  GET_ALL_CRMS,
  GET_ALL_CRMS_SUCCESS,
  GET_ALL_CRMS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  crms: [],
  error: {},
  loading: false,
}

const Crms = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CRMS:
      return {
        ...state,
        loading: true,
      }

    case GET_ALL_CRMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    // ..............................

    // ALL crms
    case GET_ALL_CRMS_SUCCESS:
      return {
        ...state,
        crms: action.payload,
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Crms
