import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ORDERS,
  GET_ORDER_DETAILS,
  GET_USER_ORDER_DETAILS,
  UPDATE_ORDER_ITEM,
  GET_STARRED_ORDERS,
  GET_CANCELLED_ORDERS,
  GET_RETURNED_ORDERS,
  UPDATE_ORDER,
  UPDATE_PAYMENT,
  UPDATE_ORDER_AWB_NUMBER,
  CREATE_SHIPROCKET_ORDER,
  ADD_ITEM_TO_USER_CART,
  CREATE_USER_ORDER,
  WHATSAPP_ORDER_ONLINE_MSG,
  GET_TOTAL_ORDER_COUNTS,
  GET_SHIPROCKET_LABEL,
  GET_SHIPROCKET_INVOICE,
  ORDER_AUTO_STATUS_UPDATE,
  CREATE_ORDER_EDIT,
} from "./actionTypes"
import {
  getOrdersSuccess,
  getOrdersFail,
  getOrderDetailsSuccess,
  getOrderDetailsFail,
  getUserOrderDetailsSuccess,
  getUserOrderDetailsFail,
  updateOrderItemSuccess,
  updateOrderItemFail,
  getStarredOrdersSuccess,
  getStarredOrdersFail,
  getCancelledOrdersSuccess,
  getCancelledOrdersFail,
  getReturnedOrdersSuccess,
  getReturnedOrdersFail,
  updateOrderSuccess,
  updateOrderFail,
  updatePaymentSuccess,
  updatePaymentFail,
  updateOrderAwbSuccess,
  updateOrderAwbFail,
  createShiprocketOrderSuccess,
  createShiprocketOrderFail,
  createUserOrdersSuccess,
  createUserOrdersFail,
  whatsappOrderOnlineFailMsgSuccess,
  whatsappOrderOnlineFailMsgFail,
  getTotalOrderCountsSuccess,
  getTotalOrderCountsFail,
  getShiprocketLabelSuccess,
  getShiprocketLabelFail,
  getShiprocketInvoiceSuccess,
  getShiprocketInvoiceFail,
  autoUpdateOrderStatusSuccess,
  autoUpdateOrderStatusFail,
  overwriteOrderDetailsSuccess,
  overwriteOrderDetailsFail,
} from "./actions"
import { get, post } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// /coupon/admin/validate/632da1aed065035f38d59f02
function getOrdersAPi({ page, limit, searchText, sort, sortByDate }) {
  if (sort) {
    return get(
      `/order/admin/all?page=${page ? page : 1}&status=${sort}&limit=${
        limit ? limit : 10
      }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}`
    )
  } else {
    return get(
      `/order/admin/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}`
    )
  }
}
const fetchOrderCountsApi = ({ searchText, sortByDate }) => {
  return get(
    `/order/admin/count/all?search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}`
  )
}

function updateAwbNumberApi({ order }) {
  return post(`/order/admin/awb`, order)
}

const createShiprocketOrderApi = ({ data }) => {
  return post(`/shiprocket-data/order/create`, data)
}

const getOrderDetailsAPi = orderId => {
  return get(`/order/admin/single/${orderId}`)
}

const getUserOrderDetailsAPi = ({ userId, page }) => {
  return get(`/order/admin/user/${userId}?page=${page ? page : 1}`)
}

const updateOrderItemApi = ({ order }) => {
  return post(`/order/admin/item/status`, order)
}

const updateOrderApi = ({ order }) => {
  return post(`/order/admin/status`, order)
}

const updatePaymentApi = ({ order }) => {
  return post(`/order/admin/paid/status`, order)
}

function getStarredOrdersAPi({ page }) {
  return get(`/order/admin/custom/starred?page=${page ? page : 1}&limit=10`)
}

function getCancelledOrdersAPi({ page }) {
  return get(`/order/admin/custom/cancelled?page=${page ? page : 1}&limit=10`)
}

function getReturnedOrdersAPi({ page }) {
  return get(`/order/admin/custom/returned?page=${page ? page : 1}&limit=10`)
}

function createUserOrderApi({ orderData }) {
  return post(`/order/admin/new`, orderData)
}

function whatsappOrderOnlineFailApi(orderId) {
  return post(`/whatsapp-api/message/order-online-fail/${orderId}`)
}

function getShiprocketLabelApi(shiprocketId) {
  return post(`/shiprocket-data/order/label`, shiprocketId)
}
function getShiprocketInvoiceApi(shiprocketId) {
  return post(`/shiprocket-data/order/invoice`, shiprocketId)
}

function orderAutoStatusUpdateApi(status) {
  return get(`/order/admin/auto/status/${status}`)
}
// .................................................
function orderEditApi({ order }) {
  return post(`/order/admin/manual/update`, order)
}

function* fetchOrders({ payload }) {
  try {
    const response = yield call(getOrdersAPi, payload)
    yield put(getOrdersSuccess(response))
  } catch (error) {
    yield put(getOrdersFail(error))
  }
}

function* fetchOrderDetail({ payload: orderId }) {
  try {
    const response = yield call(getOrderDetailsAPi, orderId)
    yield put(getOrderDetailsSuccess(response))
  } catch (error) {
    yield put(getOrderDetailsFail(error))
  }
}

function* fetchUserOrderDetail({ payload }) {
  try {
    const response = yield call(getUserOrderDetailsAPi, payload)
    yield put(getUserOrderDetailsSuccess(response))
  } catch (error) {
    yield put(getUserOrderDetailsFail(error))
  }
}

function* onUpdateOrderItem({ payload }) {
  try {
    const response = yield call(updateOrderItemApi, payload)
    if (response) {
      yield put(updateOrderItemSuccess(response))
      Notification({
        type: "success",
        message: "Order Item Updated Successfully!",
        title: "",
      })
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateOrderItemFail(error))
  }
}
function* onUpdateOrder({ payload }) {
  try {
    const response = yield call(updateOrderApi, payload)

    if (response) {
      yield put(updateOrderSuccess(response))
      Notification({
        type: "success",
        message: "Order Updated Successfully!",
        title: "",
      })
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateOrderFail(error))
  }
}

function* onUpdatePayment({ payload }) {
  try {
    const response = yield call(updatePaymentApi, payload)

    if (response) {
      yield put(updatePaymentSuccess(response))
      Notification({
        type: "success",
        message: "Order Updated Successfully!",
        title: "",
      })
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updatePaymentFail(error))
  }
}
function* updateAwbNumber({ payload }) {
  try {
    const response = yield call(updateAwbNumberApi, payload)
    if (response) {
      yield put(updateOrderAwbSuccess(response))
      Notification({
        type: "success",
        message: "Order Updated Successfully!",
        title: "",
      })
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateOrderAwbFail(error))
  }
}

function* createShiprocketOrder({ payload }) {
  try {
    const response = yield call(createShiprocketOrderApi, payload)
    if (response) {
      yield put(createShiprocketOrderSuccess(response))
      Notification({
        type: "success",
        message: "Shiprocket Order Created Successfully!",
        title: "",
      })
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createShiprocketOrderFail(error))
  }
}

function* fetchStarredOrders({ payload }) {
  try {
    const response = yield call(getStarredOrdersAPi, payload)
    yield put(getStarredOrdersSuccess(response))
  } catch (error) {
    yield put(getStarredOrdersFail(error))
  }
}

function* fetchCancelledOrders({ payload }) {
  try {
    const response = yield call(getCancelledOrdersAPi, payload)
    yield put(getCancelledOrdersSuccess(response))
  } catch (error) {
    yield put(getCancelledOrdersFail(error))
  }
}

function* fetchReturnedOrders({ payload }) {
  try {
    const response = yield call(getReturnedOrdersAPi, payload)
    yield put(getReturnedOrdersSuccess(response))
  } catch (error) {
    yield put(getReturnedOrdersFail(error))
  }
}

function* createUserOrder({ payload }) {
  try {
    const response = yield call(createUserOrderApi, payload)
    yield put(createUserOrdersSuccess(response))
    Notification({
      type: "success",
      message: "Order Create Successfully!",
      title: "",
    })
    payload.history.push("/orders")
  } catch (error) {
    yield put(createUserOrdersFail(error))
  }
}

function* whatsappOrderOnlineMsg({ payload }) {
  try {
    const response = yield call(whatsappOrderOnlineFailApi, payload)

    if (response) {
      yield put(whatsappOrderOnlineFailMsgSuccess(response))
      Notification({
        type: "success",
        message: "Message Send Successfully!",
        title: "",
      })
    }
  } catch (error) {
    console.log(error)
    yield put(whatsappOrderOnlineFailMsgFail(error))
  }
}

function* fetchOrderCounts({ payload }) {
  try {
    const response = yield call(fetchOrderCountsApi, payload)
    yield put(getTotalOrderCountsSuccess(response))
  } catch (error) {
    yield put(getTotalOrderCountsFail(error))
  }
}

function* getShiprocketLabel({ payload }) {
  try {
    const response = yield call(getShiprocketLabelApi, payload)
    if (response?.label_created >= 1) {
      yield put(getShiprocketLabelSuccess(response))
      Notification({
        type: "success",
        message: response?.response,
        title: "",
      })
    } else {
      yield put(getShiprocketLabelSuccess(response))
      Notification({
        type: "info",
        message: response?.response,
        title: "",
      })
    }
  } catch (error) {
    console.log(error)
    yield put(getShiprocketLabelFail(error))
  }
}
function* getShiprocketInvoice({ payload }) {
  try {
    const response = yield call(getShiprocketInvoiceApi, payload)
    if (response?.is_invoice_created >= 1) {
      yield put(getShiprocketInvoiceSuccess(response))
      Notification({
        type: "success",
        message: response?.response,
        title: "",
      })
    } else {
      yield put(getShiprocketInvoiceFail(response))
      Notification({
        type: "info",
        message: response?.response,
        title: "",
      })
    }
  } catch (error) {
    yield put(getShiprocketInvoiceFail(error))
  }
}
function* orderAutoStatusUpdate({ payload }) {
  try {
    const response = yield call(orderAutoStatusUpdateApi, payload)
    if (response) {
      yield put(autoUpdateOrderStatusSuccess(response))
      Notification({
        type: "success",
        message: response?.response,
        title: "",
      })
    } else {
      yield put(autoUpdateOrderStatusFail(response))
      Notification({
        type: "info",
        message: response?.response,
        title: "",
      })
    }
  } catch (error) {
    yield put(autoUpdateOrderStatusFail(error))
  }
}
// ......................................
function* orderEdit({ payload }) {
  console.log(payload)
  try {
    const response = yield call(orderEditApi, payload)
    if (response) {
      yield put(overwriteOrderDetailsSuccess(response))
      Notification({
        type: "success",
        message: "Order Item Updated Successfully!",
        title: "",
      })
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(overwriteOrderDetailsFail(error))
  }
}
function* orderSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(GET_ORDER_DETAILS, fetchOrderDetail)
  yield takeEvery(GET_USER_ORDER_DETAILS, fetchUserOrderDetail)
  yield takeEvery(UPDATE_ORDER_ITEM, onUpdateOrderItem)
  yield takeEvery(UPDATE_ORDER, onUpdateOrder)
  yield takeEvery(UPDATE_PAYMENT, onUpdatePayment)
  yield takeEvery(GET_STARRED_ORDERS, fetchStarredOrders)
  yield takeEvery(GET_CANCELLED_ORDERS, fetchCancelledOrders)
  yield takeEvery(GET_RETURNED_ORDERS, fetchReturnedOrders)
  yield takeEvery(UPDATE_ORDER_AWB_NUMBER, updateAwbNumber)
  yield takeEvery(CREATE_SHIPROCKET_ORDER, createShiprocketOrder)
  yield takeEvery(CREATE_USER_ORDER, createUserOrder)
  yield takeEvery(WHATSAPP_ORDER_ONLINE_MSG, whatsappOrderOnlineMsg)
  yield takeEvery(GET_TOTAL_ORDER_COUNTS, fetchOrderCounts)
  yield takeEvery(GET_SHIPROCKET_LABEL, getShiprocketLabel)
  yield takeEvery(GET_SHIPROCKET_INVOICE, getShiprocketInvoice)
  yield takeEvery(ORDER_AUTO_STATUS_UPDATE, orderAutoStatusUpdate)
  yield takeEvery(CREATE_ORDER_EDIT, orderEdit)
}

export default orderSaga
