import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  GET_ALL_CAMPAIGNS,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_ALL_CAMPAIGNS_FAIL,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  GET_CAMPAIGN_DETAILS,
  GET_CAMPAIGN_DETAILS_SUCCESS,
  GET_CAMPAIGN_DETAILS_FAIL,
} from "./actionTypes"

// create campaign
export const createCampaign = (campaign, onCloseClick) => ({
  type: CREATE_CAMPAIGN,
  payload: { campaign, onCloseClick },
})

export const createCampaignSuccess = campaign => ({
  type: CREATE_CAMPAIGN_SUCCESS,
  payload: campaign,
})

export const createCampaignFail = error => ({
  type: CREATE_CAMPAIGN_FAIL,
  payload: error,
})

// all CAMPAIGNS
export const getAllCampaigns = (
  page,
  sort,
  limit,
  searchText,
  medium,
  status
) => ({
  type: GET_ALL_CAMPAIGNS,
  payload: { page, sort, limit, searchText, medium, status },
})

export const getAllCampaignsSuccess = campaigns => ({
  type: GET_ALL_CAMPAIGNS_SUCCESS,
  payload: campaigns,
})

export const getAllCampaignsFail = error => ({
  type: GET_ALL_CAMPAIGNS_FAIL,
  payload: error,
})

// update campaign
export const updateCampaign = (campaignId, campaign, onCloseClick) => ({
  type: UPDATE_CAMPAIGN,
  payload: { campaignId, campaign, onCloseClick },
})

export const updateCampaignSuccess = Campaign => ({
  type: UPDATE_CAMPAIGN_SUCCESS,
  payload: Campaign,
})

export const updateCampaignFail = error => ({
  type: UPDATE_CAMPAIGN_FAIL,
  payload: error,
})

// delete campaigns
export const deleteCampaign = campaignId => ({
  type: DELETE_CAMPAIGN,
  campaignId,
})

export const deleteCampaignSuccess = campaign => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: campaign,
})

export const deleteCampaignFail = error => ({
  type: DELETE_CAMPAIGN_FAIL,
  payload: error,
})
