/* Create Campaign*/
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN"
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS"
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL"

/* ALL CAMPAIGN */
export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS"
export const GET_ALL_CAMPAIGNS_SUCCESS = "GET_ALL_CAMPAIGNS_SUCCESS"
export const GET_ALL_CAMPAIGNS_FAIL = "GET_ALL_CAMPAIGNS_FAIL"

/* Update Campaign*/
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS"
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL"

/* delete Campaign */
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS"
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL"
