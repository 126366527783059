/* DAILY_ORDER_LIST */
export const GET_DAILY_ORDER_LIST = "GET_DAILY_ORDER_LIST"
export const GET_DAILY_ORDER_LIST_SUCCESS = "GET_DAILY_ORDER_LIST_SUCCESS"
export const GET_DAILY_ORDER_LIST_FAIL = "GET_DAILY_ORDER_LIST_FAIL"

/* ORDER_SUMMARY */
export const GET_ORDER_SUMMARY = "GET_ORDER_SUMMARY"
export const GET_ORDER_SUMMARY_SUCCESS = "GET_ORDER_SUMMARY_SUCCESS"
export const GET_ORDER_SUMMARY_FAIL = "GET_ORDER_SUMMARY_FAIL"

/* SALE_DATA */
export const GET_SALE_DATA = "GET_SALE_DATA"
export const GET_SALE_DATA_SUCCESS = "GET_SALE_DATA_SUCCESS"
export const GET_SALE_DATA_FAIL = "GET_SALE_DATA_FAIL"

/* SALE_DATA_DOWNLOAD */
export const GET_SALE_DATA_DOWNLOAD = "GET_SALE_DATA_DOWNLOAD"
export const GET_SALE_DATA_DOWNLOAD_SUCCESS = "GET_SALE_DATA_DOWNLOAD_SUCCESS"
export const GET_SALE_DATA_DOWNLOAD_FAIL = "GET_SALE_DATA_DOWNLOAD_FAIL"

/* SALE_DATA_SUMMARY */
export const GET_SALE_DATA_SUMMARY = "GET_SALE_DATA_SUMMARY"
export const GET_SALE_DATA_SUMMARY_SUCCESS = "GET_SALE_DATA_SUMMARY_SUCCESS"
export const GET_SALE_DATA_SUMMARY_FAIL = "GET_SALE_DATA_SUMMARY_FAIL"
