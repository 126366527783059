import { AvField } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import { checkPromoterId } from "store/actions"
import PropTypes from "prop-types"

function Form({ updateData, isUpdate }) {
  const dispatch = useDispatch()

  const [checkPromoter, setCheckPromoter] = useState()

  const { promoterIdCheck } = useSelector(state => ({
    promoterIdCheck: state?.Users?.promoterIdCheck?.response,
  }))

  return (
    <>
      <Row>
        <Col className="col-12 d-flex flex-wrap">
          <div className="col-6 mb-4 px-3">
            <AvField
              label={
                <>
                  Name<sup>*</sup>
                </>
              }
              name="name"
              required
              type="text"
              value={updateData?.name ?? ""}
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              label={
                <>
                  Phone<sup>*</sup>
                </>
              }
              name="phone"
              maxLength="10"
              required
              type="phone"
              value={updateData?.phone ?? ""}
            />
          </div>
          {isUpdate ? (
            <div className="col-12 mb-4 px-3">
              <AvField
                name="email"
                required
                readOnly={updateData?.email ? true : false}
                label={
                  <>
                    Email<sup>*</sup>
                  </>
                }
                type="email"
                value={updateData?.email ?? ""}
              />
            </div>
          ) : (
            <div className="col-6 mb-4 px-3">
              <AvField
                name="email"
                required
                label={
                  <>
                    Email<sup>*</sup>
                  </>
                }
                type="email"
                value={updateData?.email ?? ""}
              />
            </div>
          )}

          {isUpdate ? (
            ""
          ) : (
            <div className="col-6 mb-4 px-3">
              <AvField
                name="password"
                required
                value={updateData?.password ?? ""}
                label={
                  <>
                    Password<sup>*</sup>
                  </>
                }
                type="password"
              />
            </div>
          )}

          <div className="col-12 mb-4 px-3">
            <AvField
              name="address"
              label="Address"
              value={updateData?.address ?? ""}
              type="textarea"
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              name="ref_id"
              required
              value={updateData?.ref_id ?? ""}
              readOnly={updateData?.ref_id ? true : false}
              label={
                <>
                  Promoter Id<sup>*</sup>
                </>
              }
              type="text"
              onChange={e => {
                setCheckPromoter(e.target.value)
                dispatch(checkPromoterId(e.target.value))
              }}
            />

            {checkPromoter && (
              <>
                {promoterIdCheck === true ? (
                  <h6 className="pt-1" style={{ color: "green" }}>
                    {" "}
                    Available
                  </h6>
                ) : (
                  <h6 className="pt-1" style={{ color: "red" }}>
                    Not Available{" "}
                  </h6>
                )}
              </>
            )}
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              required
              name="promoterPercentage"
              value={updateData?.promoterPercentage ?? ""}
              label={
                <>
                  Promoter Percentage<sup>*</sup>
                </>
              }
              type="number"
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

Form.propTypes = {
  updateData: PropTypes.object,
  isUpdate: PropTypes.bool,
}
export default Form
