export const sidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
  },
  {
    routeLink: "/orders",
    icon: "bx bxs-shopping-bags",
    title: "Orders",
  },
  {
    routeLink: "/products",
    icon: "bx bxs-package",
    title: "Products",
  },
  {
    routeLink: "/stockUpdate",
    icon: "bx bx-store-alt",
    title: "Stock & Offer",
  },
  {
    routeLink: "/reviews",
    icon: "bx bxs-award",
    title: "Reviews",
  },
  {
    routeLink: "/#",
    icon: "bx bxs-dashboard",
    title: "Site settings",
    subTitles: [
      { title: "Categories", routeLink: "/classifications" },
      { title: "Collections", routeLink: "/collections" },
      { title: "Groups", routeLink: "/groups" },
      { title: "Pages", routeLink: "/pages/all" },
      { title: "Banners", routeLink: "/banners" },
      { title: "Coupons", routeLink: "/coupons" },
      { title: "Blogs", routeLink: "/blogs" },
    ],
  },

  // {
  //   routeLink: "/classifications",
  //   icon: "bx bxs-collection",
  //   title: "Categories",
  // },
  // {
  //   routeLink: "/collections",
  //   icon: "bx bxs-extension",
  //   title: "Collections",
  // },
  // {
  //   routeLink: "/groups",
  //   icon: "fas fa-layer-group",
  //   title: "Groups",
  // },
  // {
  //   routeLink: "/pages/all",
  //   icon: "bx bx-food-menu",
  //   title: "Pages",
  // },
  // {
  //   routeLink: "/banners",
  //   icon: "bx bxs-image",
  //   title: "Banners",
  // },
  // {
  //   routeLink: "/coupons",
  //   icon: "bx bxs-coupon",
  //   title: "Coupon",
  // },
  // {
  //   routeLink: "/blogs",
  //   icon: "bx bx-file",
  //   title: "Blogs",
  // },

  {
    routeLink: "/#",
    icon: "",
    title: "Extras",
    isSeparator: true,
  },
  {
    routeLink: "/users",
    icon: "bx bxs-user",
    title: "Users",
  },
  {
    routeLink: "/revenues",
    icon: "bx bxs-landmark",
    title: "Revenue",
  },
  {
    routeLink: "/transactions",
    icon: "bx bx-transfer",
    title: "Transactions",
  },
  {
    routeLink: "/#",
    icon: "bx bx-hdd",
    title: "CRMS",
    subTitles: [
      { title: "Campaigns", routeLink: "/campaigns" },
      { title: "Communications", routeLink: "/crms" },
    ],
  },
  // {
  //   routeLink: "/campaigns",
  //   icon: "bx bx-hdd",
  //   title: "Campaigns",
  // },
  // {
  //   routeLink: "/crms",
  //   icon: "bx bx-command",
  //   title: "CRMS",
  // },

  // {
  //   routeLink: "/#",
  //   icon: "",
  //   title: "Site settings",
  //   isSeparator: true,
  // },
  // {
  //   routeLink: "/#",
  //   icon: "bx bxs-user-voice",
  //   title: "Promoters",
  //   subTitles: [
  //     { title: "All Promoters", routeLink: "/promoters" },
  //     { title: "All Page Views", routeLink: "/all-page-view" },
  //     { title: "All Earnings", routeLink: "/all-earnings" },
  //     { title: "Formated Earnings", routeLink: "/formatted-earnings" },
  //   ],
  // },
  {
    routeLink: "/superadmin/settings",
    icon: "bx bxs-check-shield",
    title: "Admin Settings",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Analytics",
    isSeparator: true,
  },
  {
    routeLink: "/performance/product",
    icon: "bx bxs-rocket",
    title: "Performance",
  },
  {
    routeLink: "/#",
    icon: "bx bxs-shopping-bag",
    title: "Order Data",
    subTitles: [
      { title: "Daily Order List", routeLink: "/analytics/order/daily-list" },
      { title: "Sale Data", routeLink: "/analytics/order/sale" },
    ],
  },
]

export const promoterDashboard = [
  { title: "Dashboard", routeLink: "/dashboard", icon: "bx bxs-home" },
  {
    title: " Page Views",
    routeLink: "/promoter-page-view",
    icon: "bx bx-show-alt",
  },
  {
    title: " Earnings",
    routeLink: "/promoter-earnigs",
    icon: "bx bx-dollar",
  },
  {
    title: "Products",
    routeLink: "/promoter/products",
    icon: "bx bxs-package",
  },
]
