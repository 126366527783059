import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
} from "reactstrap"
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"

import Breadcrumbs from "components/Common/Breadcrumb"
import useCloudinary from "hooks/useCloudinaryImage"

//actions
import { deleteProduct, getProductDetails } from "store/products/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { map } from "lodash"
import { CurrencySwitch } from "hooks/Currency"
import MetaTag from "components/Common/Meta-tag"
import DeleteModal from "components/Common/DeleteModal"

const ProductDetail = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const [activeTab, setActiveTab] = useState(0)

  const { productDetails, loading } = useSelector(state => ({
    productDetails: state.Products.productDetails,
    loading: state.Products.loading,
  }))
  const { settings } = useSelector(state => ({
    settings: state.Settings.settings,
  }))

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteProduct(isOpen?.id, history))
  }
  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  useEffect(() => {
    dispatch(getProductDetails(params.id))
  }, [dispatch])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const imageShow = (img, id) => {
    const expandImg = {}
    expandImg.src = document.getElementById("expandedImg")
  }

  const [activeTabNew, setActiveTabNew] = useState("1")

  const toggle = tab => {
    if (activeTabNew !== tab) {
      setActiveTabNew(tab)
    }
  }

  const extraContents = [
    {
      value: productDetails?.isBulkyProduct,
      title: "BulkyProduct,",
    },
    {
      value: productDetails?.isDiscounted,
      title: "Discounted,",
    },
    {
      value: productDetails?.isExtraChargeNeeded,
      title: "ExtraChargeNeeded,",
    },
    {
      value: productDetails?.isFeatured,
      title: "Featured,",
    },

    {
      value: productDetails?.isFitted,
      title: "Fitted,",
    },
    {
      value: productDetails?.isFittingNeeded,
      title: "FittingNeeded,",
    },
    {
      value: productDetails?.isReturnViable,
      title: "ReturnViable,",
    },
    {
      value: productDetails?.isTaxIncluded,
      title: "TaxIncluded,",
    },
  ]

  const classificationData = [
    {
      titlePri: "Category",
      titleSec: "الفئة",
      valuePri: productDetails?.category?.primaryLang?.name,
      valueSec: productDetails?.category?.secondaryLang?.name,
    },
    // {
    //   titlePri: "Sub Category",
    //   titleSec: "تصنيف فرعي",
    //   valuePri: productDetails?.subCategory?.primaryLang?.name,
    //   valueSec: productDetails?.subCategory?.secondaryLang?.name,
    // },
    // {
    //   titlePri: "Brand",
    //   titleSec: "ماركة",
    //   valuePri: productDetails?.brand?.primaryLang?.name,
    //   valueSec: productDetails?.brand?.secondaryLang?.name,
    // },
    // {
    //   titlePri: "Age Group",
    //   titleSec: "الفئة العمرية",
    //   valuePri: productDetails?.ageGroup?.primaryLang?.name,
    //   valueSec: productDetails?.ageGroup?.secondaryLang?.name,
    // },

    // {
    //   titlePri: "Gender Type",
    //   titleSec: "النوع",
    //   valuePri: productDetails?.genderType?.primaryLang?.name,
    //   valueSec: productDetails?.genderType?.secondaryLang?.name,
    // },
  ]

  const currency = CurrencySwitch()

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <div className="page-content">
        <MetaTag title="Product Details" />

        <Container fluid>
          <Breadcrumbs title="Product" breadcrumbItem="Product Details" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="6" lg={12} md={12} className="mb-4">
                      <div className="product-detai-imgs">
                        <Row>
                          <Col md="2" xs="3">
                            <Nav className="flex-column" pills>
                              {productDetails?.images?.map((image, index) => (
                                <NavItem key={index}>
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === { index },
                                    })}
                                    onClick={() => {
                                      toggleTab(index)
                                    }}
                                  >
                                    <img
                                      src={useCloudinary(image.url, "w_900")}
                                      alt=""
                                      onClick={() => {
                                        imageShow(image.url, index)
                                      }}
                                      className="img-fluid mx-auto d-block rounded"
                                    />
                                  </NavLink>
                                </NavItem>
                              ))}
                            </Nav>
                          </Col>
                          <Col
                            md={{ size: 7, offset: 1 }}
                            xs="9"
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div />
                            <TabContent activeTab={activeTab}>
                              {productDetails?.images?.map((image, index) => (
                                <TabPane key={index} tabId={index}>
                                  <div>
                                    <img
                                      // src={image.url}
                                      src={useCloudinary(image.url, "w_900")}
                                      alt=""
                                      id="expandedImg1"
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                              ))}
                            </TabContent>
                            <div className="text-center">
                              <div>
                                <Link
                                  to={`/product/update/${productDetails?._id}`}
                                >
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn  mt-2 me-1"
                                  >
                                    <i className="bx bx-edit me-2" /> Update
                                  </Button>
                                </Link>
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1 btn mt-2"
                                  onClick={() => {
                                    handleDelete(productDetails?._id)
                                  }}
                                >
                                  <i className="bx bx-trash me-2" />
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col xl="6" lg={12} md={12}>
                      <div className="flex-wrap d-flex align-items-center justify-content-start">
                        {productDetails?.itemCode && (
                          <>
                            <span>
                              itemCode :{" "}
                              <span style={{ color: "#808080de" }}>
                                {productDetails?.itemCode}
                              </span>
                            </span>
                          </>
                        )}
                      </div>
                      <div>
                        <h4 className="mt-2">
                          {productDetails?.primaryLang?.name}
                        </h4>
                        {productDetails?.secondaryLang && (
                          <h4 className="mt-2">
                            {productDetails?.secondaryLang?.name}
                          </h4>
                        )}
                      </div>

                      <>
                        <CardHeader className="bg-transparent border-bottom mt-3">
                          <div className="d-flex flex-wrap">
                            <ul
                              className="nav nav-tabs card-header-tabs "
                              role="tablist"
                            >
                              <NavItem className="">
                                <NavLink
                                  to="#"
                                  className={classnames({
                                    active: activeTabNew === "1",
                                  })}
                                  onClick={() => {
                                    toggle("1")
                                  }}
                                >
                                  DETAILS
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTabNew === "2",
                                  })}
                                  onClick={() => {
                                    toggle("2")
                                  }}
                                >
                                  FEATURES
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTabNew === "3",
                                  })}
                                  onClick={() => {
                                    toggle("3")
                                  }}
                                >
                                  CLASSIFICATIONS
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTabNew === "4",
                                  })}
                                  onClick={() => {
                                    toggle("4")
                                  }}
                                >
                                  TAX / PRICES
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTabNew === "5",
                                  })}
                                  onClick={() => {
                                    toggle("5")
                                  }}
                                >
                                  Other Details
                                </NavLink>
                              </NavItem>
                            </ul>
                          </div>
                        </CardHeader>

                        <CardBody>
                          <div>
                            <TabContent activeTab={activeTabNew}>
                              <TabPane className="show" tabId="1">
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item p-0">
                                    <div className="">
                                      <div className="d-flex align-self-center overflow-hidden me-auto">
                                        <h5 className="font-size-14 text-truncate">
                                          Stock :
                                        </h5>
                                        <h6 className="text-info mb-0 mx-3">
                                          {productDetails?.stockValue}
                                        </h6>
                                      </div>

                                      {productDetails?.primaryLang
                                        ?.description && (
                                        <div className="align-self-center overflow-hidden me-auto">
                                          <h5 className="font-size-14 text-truncate">
                                            Description
                                          </h5>
                                          <p className="text-muted mb-0">
                                            {
                                              productDetails?.primaryLang
                                                ?.description
                                            }
                                          </p>
                                        </div>
                                      )}
                                      {productDetails?.secondaryLang
                                        ?.description && (
                                        <div className="align-self-center overflow-hidden me-auto mt-3">
                                          <h5 className="font-size-14 text-truncate">
                                            وصف
                                          </h5>
                                          <p className="text-muted mb-0">
                                            {
                                              productDetails?.secondaryLang
                                                ?.description
                                            }
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </TabPane>

                              <TabPane className="show" tabId="2">
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item p-0">
                                    <div className="">
                                      {map(
                                        productDetails?.primaryLang?.specs,
                                        (item, key) => (
                                          <div
                                            key={key}
                                            className="align-self-center d-flex overflow-hidden me-auto mb-2"
                                          >
                                            <h5 className="font-size-14 text-truncate">
                                              {item?.title} :
                                            </h5>
                                            <p className="text-muted mb-0 mx-3">
                                              {item?.details}
                                            </p>
                                          </div>
                                        )
                                      )}

                                      {map(
                                        productDetails?.secondaryLang?.specs,
                                        (item, key) => (
                                          <div
                                            key={key}
                                            className="align-self-center d-flex overflow-hidden me-auto mb-2"
                                          >
                                            <h5 className="font-size-14 text-truncate">
                                              {item?.title} :
                                            </h5>
                                            <p className="text-muted mb-0 mx-3">
                                              {item?.details}
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <Row>
                                      {productDetails?.keywords?.length ? (
                                        <Col className="col-6">
                                          <CardTitle>Keywords</CardTitle>{" "}
                                          {map(
                                            productDetails?.keywords,
                                            (item, key) => (
                                              <div
                                                key={key}
                                                className=" align-self-center d-flex overflow-hidden me-auto mb-2"
                                              >
                                                <h5 className="font-size-14 text-truncate">
                                                  {item}
                                                </h5>
                                              </div>
                                            )
                                          )}
                                        </Col>
                                      ) : (
                                        ""
                                      )}
                                    </Row>
                                  </li>
                                </ul>
                              </TabPane>

                              <TabPane className="show" tabId="3">
                                <ul className="list-group list-group-flush">
                                  {map(classificationData, (item, key) => (
                                    <Fragment key={key}>
                                      {item.valuePri ? (
                                        <li className="list-group-item p-0 py-2">
                                          <div className="d-flex ">
                                            {item.valuePri && (
                                              <div className="align-self-center d-flex overflow-hidden me-auto">
                                                <h5 className="font-size-14 text-truncate">
                                                  {item.titlePri} :
                                                </h5>
                                                <p className="text-muted mb-0 mx-2">
                                                  {item.valuePri}
                                                </p>
                                              </div>
                                            )}

                                            {item.valueSec && (
                                              <div className="align-self-center d-flex  overflow-hidden me-auto">
                                                <h5 className="font-size-14 text-truncate">
                                                  {item.titleSec} :
                                                </h5>
                                                <p className="text-muted mb-0 mx-2">
                                                  {item.valueSec}
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  ))}
                                </ul>
                              </TabPane>

                              <TabPane className="show" tabId="4">
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item p-0">
                                    <div className="">
                                      {productDetails?.cost ? (
                                        <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                          <h5 className="font-size-14 text-truncate">
                                            Cost :
                                          </h5>
                                          <p className="text-muted mb-0 mx-3">
                                            {currency} {productDetails?.cost}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {productDetails?.price ? (
                                        <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                          <h5 className="font-size-14 text-truncate">
                                            Price :
                                          </h5>
                                          <p className="text-muted mb-0 mx-3">
                                            {currency} {productDetails?.price}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {productDetails?.sellingPrice ? (
                                        <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                          <h5 className="font-size-14 text-truncate">
                                            SellingPrice :
                                          </h5>
                                          <p className="text-info mb-0 mx-3">
                                            {currency}{" "}
                                            {productDetails?.sellingPrice}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {productDetails?.profit ? (
                                        <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                          <h5 className="font-size-14 text-truncate">
                                            Profit :
                                          </h5>
                                          <p className="text-muted mb-0 mx-3">
                                            {productDetails?.profit} %
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {productDetails?.discountPercentage ? (
                                        <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                          <h5 className="font-size-14 text-truncate">
                                            DiscountPercentage :
                                          </h5>
                                          <p className="text-muted mb-0 mx-3">
                                            {productDetails?.discountPercentage}{" "}
                                            %
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {productDetails?.discountValue ? (
                                        <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                          <h5 className="font-size-14 text-truncate">
                                            DiscountValue :
                                          </h5>
                                          <p className="text-muted mb-0 mx-3">
                                            {currency}{" "}
                                            {productDetails?.discountValue}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {productDetails?.extraCharge ? (
                                        <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                          <h5 className="font-size-14 text-truncate">
                                            ExtraCharge :
                                          </h5>
                                          <p className="text-muted mb-0 mx-3">
                                            {currency}{" "}
                                            {productDetails?.extraCharge}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {productDetails?.taxValue ? (
                                        <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                          <h5 className="font-size-14 text-truncate">
                                            Tax :
                                          </h5>
                                          <p className="text-muted mb-0 mx-3">
                                            {currency}{" "}
                                            {productDetails?.taxValue}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="align-self-center d-flex overflow-hidden me-auto mb-2">
                                        <h5 className="font-size-14 text-truncate">
                                          ExtraCharge :
                                        </h5>
                                        <p className="text-muted mb-0 mx-3">
                                          {currency}{" "}
                                          {productDetails?.extraCharge}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </TabPane>

                              <TabPane className="show" tabId="5">
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item p-0">
                                    <div className="">
                                      {map(extraContents, (item, key) => (
                                        <div
                                          key={key}
                                          className="align-self-center d-flex overflow-hidden me-auto mb-2"
                                        >
                                          <h5 className="font-size-14 text-truncate">
                                            {item?.title} :
                                          </h5>
                                          <p className="text-muted mb-0 mx-3">
                                            {item?.value ? (
                                              <span className="m-0 p-0 text-success">
                                                ture
                                              </span>
                                            ) : (
                                              "false"
                                            )}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  </li>
                                </ul>
                              </TabPane>
                            </TabContent>
                          </div>
                        </CardBody>
                      </>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
}

export default ProductDetail
