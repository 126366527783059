import {
  GET_DAILY_ORDER_LIST,
  GET_DAILY_ORDER_LIST_SUCCESS,
  GET_DAILY_ORDER_LIST_FAIL,
  GET_ORDER_SUMMARY,
  GET_ORDER_SUMMARY_SUCCESS,
  GET_ORDER_SUMMARY_FAIL,
  GET_SALE_DATA,
  GET_SALE_DATA_SUCCESS,
  GET_SALE_DATA_FAIL,
  GET_SALE_DATA_DOWNLOAD,
  GET_SALE_DATA_DOWNLOAD_SUCCESS,
  GET_SALE_DATA_DOWNLOAD_FAIL,
  GET_SALE_DATA_SUMMARY,
  GET_SALE_DATA_SUMMARY_SUCCESS,
  GET_SALE_DATA_SUMMARY_FAIL,
} from "./actionTypes"

// Daily Order List
export const getDailyOrderList = (
  page,
  limit,
  year,
  month,
  searchText,
  sort,
  sortByDate
) => ({
  type: GET_DAILY_ORDER_LIST,
  payload: { page, limit, year, month, searchText, sort, sortByDate },
})

export const getDailyOrderListSuccess = dailyOrderList => ({
  type: GET_DAILY_ORDER_LIST_SUCCESS,
  payload: dailyOrderList,
})

export const getDailyOrderListFail = error => ({
  type: GET_DAILY_ORDER_LIST_FAIL,
  payload: error,
})

// Order Summary
export const getOrderSummary = status => ({
  type: GET_ORDER_SUMMARY,
  payload: { status },
})

export const getOrderSummarySuccess = orderSummary => ({
  type: GET_ORDER_SUMMARY_SUCCESS,
  payload: orderSummary,
})

export const getOrderSummaryFail = error => ({
  type: GET_ORDER_SUMMARY_FAIL,
  payload: error,
})

// Sale Data
export const getSaleData = (
  page,
  limit,
  year,
  month,
  searchText,
  sort,
  sortByDate
) => ({
  type: GET_SALE_DATA,
  payload: { page, limit, year, month, searchText, sort, sortByDate },
})

export const getSaleDataSuccess = saleData => ({
  type: GET_SALE_DATA_SUCCESS,
  payload: saleData,
})

export const getSaleDataFail = error => ({
  type: GET_SALE_DATA_FAIL,
  payload: error,
})

// Sale Data Download
export const getSaleDataDownload = (
  page,
  limit,
  year,
  month,
  searchText,
  sort,
  sortByDate
) => ({
  type: GET_SALE_DATA_DOWNLOAD,
  payload: { page, limit, year, month, searchText, sort, sortByDate },
})

export const getSaleDataDownloadSuccess = saleData => ({
  type: GET_SALE_DATA_DOWNLOAD_SUCCESS,
  payload: saleData,
})

export const getSaleDataDownloadFail = error => ({
  type: GET_SALE_DATA_DOWNLOAD_FAIL,
  payload: error,
})

// Sale Data Total
export const getSaleDataSummary = (
  page,
  limit,
  year,
  month,
  searchText,
  sort,
  sortByDate
) => ({
  type: GET_SALE_DATA_SUMMARY,
  payload: { page, limit, year, month, searchText, sort, sortByDate },
})

export const getSaleDataSummarySuccess = saleDataSummary => ({
  type: GET_SALE_DATA_SUMMARY_SUCCESS,
  payload: saleDataSummary,
})

export const getSaleDataSummaryFail = error => ({
  type: GET_SALE_DATA_SUMMARY_FAIL,
  payload: error,
})
